<template>
  <van-config-provider
    v-if="detail && detail.length > 0"
    :theme-vars="{
      dialogBorderRadius: '24px'
    }"
  >
    <van-dialog
      v-model:show="show"
      overlay-class="award-modal"
      :show-confirm-button="false"
      :width="modalWidth"
      style="background: transparent; top: 50%;"
      class="custom-animation-zoom-in"
    >
      <div
        class="content-box"
        :style="{ width: `${modalWidth}px` }"
      >
        <div
          class="content text-dark relative bg-card"
          :class="{
            'content-in-mobile' : isMobile && !isIpad,
            'content-in-ipad': isIpad,
          }"
          @touchmove.stop
        >
          <div
            class="absolute right-4 top-4 cursor-pointer z-50"
            @click="handleClose"
          >
            <img
              class="w-5 h-5"
              :src="CloseIcon"
            >
          </div>
          <div class="content-body">
            <div class="flex justify-center">
              <prize-result-title
                text="恭喜以下队伍获奖"
              />
            </div>
            <div class="flex items-center justify-center mt-3">
              <prize-tag
                :text="detail[0]?.awardsName"
              />
            </div>
            <div
              class="team-wrap mt-5 grid relative"
              :class="{
                'grid-cols-2': !isMobile || isLandscape || isIpad,
                'grid-cols-1': isMobile && !isLandscape,
                'overflow-y-auto': !isMobile || !isLandscape || isIpad,
              }"
            >
              <div
                v-for="(item, i) in detail"
                :key="i"
                :class="{
                  'divider-right':(!isMobile || isLandscape || isIpad) && i === 0
                }"
              >
                <div
                  :class="{
                    'mx-5': !isMobile || isLandscape || isIpad,
                    'mx-2': isMobile && !isLandscape && !isIpad,
                  }"
                >
                  <div class="flex justify-between">
                    <div class="text-sm font-medium">[团圆大奖]</div>
                    <div
                      v-if="item.teamList && item.teamList.length > 0"
                      class="text-gray-04 text-sm"
                    >{{ item.teamList.length }}支队伍</div>
                  </div>
                  <div class="assets-name text-sm font-medium">{{ item.assetsName }}</div>
                </div>
                <div
                  v-if="item.teamList && item.teamList.length > 0"
                  class="mt-3"
                >
                  <div
                    v-for="(team, index) in item.teamList"
                    :key="index"
                    class="team-item flex items-center border border-dark rounded p-1"
                    :class="{
                      'team-item-in-ipad': isIpad,
                    }"
                  >
                    <img
                      class="w-14 h-14 rounded"
                      :src="team.teamPicUrl"
                    >
                    <div class="ml-3">
                      <div
                        class="text-base font-semibold mb-0.5"
                        style="color: #262626"
                      >
                        {{ team.teamName }}
                      </div>
                      <div class="text-sm">队长：{{ team.teamLeaderName }}</div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="i !== detail.length - 1"
                  class="divider-line"
                />
              </div>
            </div>
            <div
              class="shadow-item"
            />
          </div>
          <div
            class="relative py-4"
            style="box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04)"
          >
            <div
              class="text-xs font-medium text-center"
            >
              以上队伍的队员都可获得&nbsp;<span style="color: #FA694A">500&nbsp;薯券</span>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </van-config-provider>
</template>

<script lang='ts'>
  import {
    defineComponent, ref, computed, onMounted,
  } from 'vue'
  import { Dialog, ConfigProvider } from 'vant'
  import CloseIcon from '~/assets/close.svg'
  import ArrowRightIcon from '~/assets/arrow-right.svg'
  import { isMobile, isIpad } from '~/utils/browserCheck'
  import PrizeTag from './PrizeTag.vue'
  import PrizeResultTitle from './PrizeResultTitle.vue'

  export default defineComponent({
    name: 'TeamWinPrizeModal',

    components: {
      VanDialog: Dialog.Component,
      VanConfigProvider: ConfigProvider,
      PrizeResultTitle,
      PrizeTag,
    },

    props: {
      isWin: {
        type: Boolean,
        default: true,
      },
      detail: {
        type: Array,
        default: () => ([]),
      },
    },

    emits: ['close'],

    setup(props, { emit }) {
      const show = ref(true)
      const isLandscape = ref(false)

      const modalWidth = computed(() => {
        if (!isMobile || isIpad) {
          return 640
        }
        if ((isMobile && !isIpad) && isLandscape.value) {
          return 640
        }
        return 320
      })

      const setLandscapeOrientation = () => {
        isLandscape.value = window.orientation === 90 || window.orientation === -90
      }

      onMounted(() => {
        setLandscapeOrientation()
        const eventname = 'onorientationchange' in window ? 'orientationchange' : 'resize'
        window.addEventListener(eventname, () => {
          if (isMobile) {
            setLandscapeOrientation()
          }
        })
      })

      return {
        CloseIcon,
        ArrowRightIcon,

        show,
        isMobile,
        isIpad,
        isLandscape,
        modalWidth,

        handleClose() {
          emit('close')
        },
      }
    },
  })
</script>

<style lang="stylus" scoped>
  .content-box
    background-image linear-gradient(180deg, #FFCC77 0%, #EB3B23 100%)
    padding 2px
    border-radius 24px
  .content
    .shadow-item
      position: absolute
      width: 100%
      height: 16px
      background: linear-gradient(180deg, rgba(235, 59, 35, 0.1) 0%, rgba(235, 59, 35, 0) 53.12%)
      transform: matrix(1, 0, 0, -1, 0, 0)
      left: 0px
      bottom: 50px
  .content
  .content-in-ipad
    border-radius 24px
    padding 32px 0px 0px 0px
    .team-wrap
      .divider-line
        display: none
  .content-in-mobile
    border-radius 24px
    padding 32px 8px 0px 8px
    .team-wrap
      .divider-line
        display: block
        height: 0
        border-bottom: 1px dashed #000
        margin: 16px 8px
    @media screen and (orientation:landscape)
      padding 24px 8px 0px 8px
      .content-body
        overflow-y: auto
        height: 236px
      .team-wrap
        .divider-line
          display: none
  .team-wrap
    max-height 320px
    .divider-line
      display: none
    .divider-right
      border-right: 1px solid #1B0D02
  .team-item
    margin 0px 24px 10px 24px
    width 272px
    height 64px
    @media screen and (max-width:960px)
      width 288px
      margin 0px 8px 10px 8px
    @media screen and (max-width:960px) and (orientation:landscape)
      margin 0px 16px 10px 16px
      width 272px
      height 64px
  .team-item-in-ipad
    margin 0px 24px 10px 24px
    width 272px
    height 64px
  .assets-name
    overflow hidden
    text-overflow ellipsis
    display: -webkit-box
    -webkit-line-clamp 2
    -webkit-box-orient vertical
</style>
