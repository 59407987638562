

#app
  .postPage
    .van-tab
      flex 0 1 auto
    .van-tabs__line
      width 14px
    .van-tab__text--ellipsis
      overflow: visible
  .lottery-wrapper .van-overlay
    background-color rgba(0,0,0,.5)
  .van-divider
    border-color #e3e3e3
  .van-search .van-icon__image
    margin-top 4px