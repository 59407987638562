<template>
  <div
    v-if="announcement && showNotice || list.length"
    :class="['bg-gray-brand','w-full','top-message p-3 pb-0',isFold?'isFold':'']"
    :style="{ maxHeight }"
    @touchmove.stop=""
  >
    <div
      v-if="announcement && showNotice"
      class="flex leading-5 p-3 relative bg-gray-07 rounded-md"
    >
      <div>
        <a-tooltip
          :overlay-style="{visibility:isMobile?'hidden':'visible',zIndex:100008}"
        >
          <template #title>关闭公告</template>
          <div class="close-icon-wrap inline-flex items-center justify-center w-3 h-3 cursor-pointer absolute rounded-sm z-50">
            <img
              class="w-full h-full"
              :src="CloseIcon"
              @click="emit('close-notice')"
            >
          </div>
        </a-tooltip>
        <span
          class="h-5 px-1 mr-1.5 text-xs text-blue-brand bg-blue-sub rounded"
          style="padding-top: 1px; padding-bottom: 1px; vertical-align: text-top;"
        >公告</span>
        <span
          class="text-gray-01 break-all"
          v-html="transChatMessage(announcement)"
        />
      </div>
    </div>
    <div
      v-if="list && list.length > 0"
      class="pb-3 bg-gray-07 rounded-md"
      :class="{'mt-2':announcement && showNotice}"
    >
      <div class="flex items-center justify-between">
        <div class="flex flex-1 mb-3 justify-between items-center h-8 px-3 py-2 text-xs text-orange-brand bg-orange-sub rounded rounded-b-none">
          精选评论·{{ list.length }}
          <div
            v-if="list && list.length > 1 || isOverflow"
            class="inline-flex items-center justify-center h-4 cursor-pointer"
            @click="toggle"
          >
            <span>{{ isFold?'展开':'收起' }}</span>
            <img
              class="w-3 h-3 arrow-icon"
              :src="isFold ? ArrowDownIcon : ArrowUpIcon"
            >
          </div>
        </div>
      </div>
      <div>
        <div
          v-for="(item, index) in list"
          :key="index"
          class="flex items-center justify-between px-3"
          :class="{'top-item-mb':!isFold}"
        >
          <template v-if="!isFold || (index === 0 && isFold)">
            <div
              class="relative topText"
              :class="(index === 0 && isFold) ? ['whitespace-nowrap overflow-hidden overflow-ellipsis text-gray-01'] : []"
            >
              <span class="text-gray-03">{{ item.userName }}：</span>
              <span
                class="text-gray-01 break-all"
                v-html="transChatMessage(item.text)"
              />
              <div
                class="originalText"
                v-html="transChatMessage(`${item.userName}：${item.text}`)"
              />
            </div>
            <a-tooltip
              :overlay-style="{visibility:isMobile?'hidden':'visible',zIndex:100008}"
            >
              <template #title>取消精选</template>
              <img
                v-if="isManager"
                class="w-4 h-4 flex-shrink-0 ml-4 cursor-pointer"
                :src="CancelTop"
                alt="取消精选"
                @click="emit('cancel-topping', item.messageId)"
              >
            </a-tooltip>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { isMobile } from 'shared/utils/browserCheck'
  import {
    defineComponent,
    ref,
    watchEffect,
    PropType,
    onMounted,
    onUnmounted,
  } from 'vue'
  import { throttle } from 'lodash'
  import CloseIcon from '~/assets/close.png'
  import ArrowDownIcon from '~/assets/line-arrow-down.png'
  import ArrowUpIcon from '~/assets/line-arrow-up.png'
  import CancelTop from '~/assets/cancel-top.png'
  import { transChatMessage } from '~/utils/transChatMessage'

  interface Imessage {
    id: number
    userName: string
    text: string
  }

  export default defineComponent({
    name: 'TopMessage',
    props: {
      announcement: {
        type: String,
        default: '',
      },
      showNotice: {
        type: Boolean,
        default: false,
      },
      list: {
        type: Array as PropType<Imessage[]>,
        default: () => ([]),
      },
      isManager: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close-notice', 'cancel-topping', 'toggle-fold'],
    setup(props, { emit }) {
      const isFold = ref(true)
      function toggle() {
        isFold.value = !isFold.value
        emit('toggle-fold', isFold.value)
      }
      const isOverflow = ref(false)

      const maxHeight = ref<string>('25vh')

      function calcTopHeight() {
        const chatContainer:HTMLElement | null = document.querySelector('.chat-container')
        if (chatContainer) {
          maxHeight.value = `${Math.round(chatContainer.offsetHeight / 3)}px` || '25vh'
        }
      }

      function calcOverflow() {
        setTimeout(() => {
          calcTopHeight()
          const topMessageEle = document.querySelector('.top-message')
          const topTextEle = topMessageEle?.querySelector('.topText')
          const originalTextEle = topMessageEle?.querySelector('.originalText')
          if (props.list.length <= 1 && topTextEle && originalTextEle) {
            isOverflow.value = isFold.value ? Number(topTextEle?.clientHeight || 0) !== Number((originalTextEle?.clientHeight || 0)) : originalTextEle?.clientHeight > 22
          }
        })
      }

      const handleResize = throttle(() => {
        calcTopHeight()
      }, 100)

      watchEffect(() => {
        if (props.list.length) {
          calcOverflow()
        }
      })

      onMounted(() => {
        calcOverflow()
        window.addEventListener('resize', handleResize, false)
      })

      onUnmounted(() => {
        window.removeEventListener('resize', handleResize)
      })

      return {
        isOverflow,
        CloseIcon,
        ArrowDownIcon,
        ArrowUpIcon,
        CancelTop,
        emit,
        isFold,
        toggle,
        transChatMessage,
        isMobile,
        maxHeight,
      }
    },
  })
</script>

<style lang="stylus" scoped>
  .top-message
    border-bottom: 1px solid rgba(42 42 50, 0.6)
    box-shadow: 0 2px 4px 0px rgba(0,0,0,0.3)
    .top-item-mb:not(:last-of-type)
      margin-bottom: 6px
    &::after
      content: ''
      position: sticky
      display: block
      width: 100%
      bottom: -1px
      height: 10px
      background-color: #19191F
    .divider
      width: 1px
      background-color: #fff
      opacity: 0.15
      margin: 0 6px
      height: 10px
    .arrow-icon
      margin: 0 2px
    .close-icon-wrap
      top: 4px
      right: 4px
    .originalText
      visibility: hidden
      position: absolute
      width: 100%
      white-space: pre-wrap
      left: 0
      top:0
</style>
