<template>
  <div
    class="Pendants absolute"
    :class="[
      isFullScreen?'pendants-pc-fullscreen':'',
      isCssFullScreen?'pendants-css-fullscreen':'',
      isMobile ?'pendants-mobile':'',
      isIPad?'pendants-ipad':''
    ]"
  >
    <div
      v-for="widget in widgetList"
      :key="widget.id"
      class="pendant-content"
      @click="handlePendantClick(widget)"
    >
      <img :src="widget.pic">
      <span
        v-if="![WidgetType.celebration2022KO,WidgetType.celebration2022Night].includes(widget.type)"
        class="pendant-time"
      >{{ countDownDisplay(widget) }}</span>
    </div>
  </div>
</template>
<script lang='ts'>
  import {
    defineComponent, onMounted, ref, watchEffect, onUnmounted,
  } from 'vue'
  import { useEventBus } from '@vueuse/core'
  import { BROADCAST_TYPE, IM_EVENT_TYPE } from 'shared/config/live.config'
  import { getWidgetListRequest } from 'shared/services/weidget'
  import { IWidget } from 'shared/types/IWidget'
  import showToast from 'shared/utils/showToast'
  import { isMobile } from '@xhs/efficiency-tool'
  import { isIPad } from 'shared/utils/isIPad'
  import { WidgetType } from 'shared/enums/WidgetType'
  import useNoticeUpdate from '~/composables/useNoticeUpdate'
  import { secondsToCountdown } from '~/utils/secondsToCountdown'
  import giftSvg from '~/assets/gift.svg'

  type Timer = {
    totalTime:number
    countDown:number
  }

  export default defineComponent({
    props: {
      roomId: { type: String, required: true },
      isWebFullScreen: { type: Boolean, required: true },
      isFullScreen: { type: Boolean, required: true },
      isCssFullScreen: { type: Boolean, required: true },
    },
    emits: ['show-award-modal'],
    setup(props, { emit }) {
      const widgetList = ref<IWidget[]>([])

      const timer = ref<any>(null)

      const widgetTimerMap = ref<{[T:string]:Timer}>({})

      const { on } = useEventBus<string>(IM_EVENT_TYPE.PENDANT_CHANGE)

      const fetchWidgetList = async () => {
        const res = await getWidgetListRequest(props.roomId)

        widgetList.value = res.filter(item => item.show)
        widgetTimerMap.value = widgetList.value.reduce((prev, item) => ({
          ...prev,
          [String(item.id)]: {
            totalTime: Math.round((item.endTime - item.currentTime) / 1000),
            countDown: Math.round(item.timeCount / 1000),
          },
        }), {})
      }

      const handlePendantClick = (widget:IWidget) => {
        const widgetTimer = widgetTimerMap.value[widget.id]
        if (![WidgetType.celebration2022KO, WidgetType.celebration2022Night].includes(widget.type)) {
          if (widgetTimer.totalTime > 0) {
            showToast('暂未开始')
            return
          }
        }
        emit('show-award-modal', widget)
      }

      const countDownDisplay = (widget:IWidget) => {
        const widgetTimer = widgetTimerMap.value[widget.id]
        if (widgetTimer.totalTime > widgetTimer.countDown) {
          return '未开始'
        }
        if (widgetTimer.totalTime < 0) {
          return '已开奖'
        }
        if (widgetTimer.totalTime === 0) {
          emit('show-award-modal', widget)
        }
        return secondsToCountdown(widgetTimer?.totalTime)
      }

      on(fetchWidgetList)

      const setTimer = () => {
        if (timer.value) clearInterval(timer.value)
        timer.value = setInterval(() => {
          widgetTimerMap.value = Object.keys(widgetTimerMap.value).reduce((prev, cur) => {
            const item = widgetTimerMap.value[cur]
            return {
              ...prev,
              [cur]: {
                countDown: item.countDown,
                totalTime: item.totalTime - 1,
              },
            }
          }, {})
        }, 1000)
      }

      const visibleChange = (event:any) => {
        if (!event?.target?.hidden) {
          fetchWidgetList()
        }
      }

      useNoticeUpdate(BROADCAST_TYPE.PENDANT_STATE, fetchWidgetList)

      watchEffect(() => {
        if (Object.keys(widgetTimerMap.value).length) {
          setTimer()
        }
        if (Object.values(widgetTimerMap.value).every(item => item.totalTime < 0)) {
          clearInterval(timer.value)
        }
      })

      onMounted(() => {
        fetchWidgetList()
        document.addEventListener('visibilitychange', visibleChange)
      })

      onUnmounted(() => {
        document.removeEventListener('visibilitychange', visibleChange)
      })

      return {
        handlePendantClick,
        giftSvg,
        widgetList,
        widgetTimerMap,
        secondsToCountdown,
        countDownDisplay,
        isMobile: isMobile(),
        isIPad: isIPad(),
        WidgetType,
      }
    },
  })
</script>
<style lang='stylus' scoped>
@keyframes gift-rotate
  0% {
    transform: rotate(-6deg)
  }

  50% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(6deg)
  }
.Pendants
  user-select: none
  z-index 100001
  right: 330px
  bottom: 60px
  &.pendants-pc-fullscreen
    right: 26px
  &.pendants-mobile
    bottom: 68px
    right: 10px
    @media screen and (orientation:landscape)
      right: 280px
      bottom: 56px
  &.pendants-ipad
    bottom: 160px
    right: 80px
    @media screen and (orientation:landscape)
      right: 320px
      bottom: 120px
  &.pendants-css-fullscreen
    @media screen and (orientation:landscape)
      right: 26px
  .pendant-content
    cursor: pointer
    margin-top: 5px
    position: relative
    width: 80px
    height: 80px
    will-change: transform
    transform: rotate(0deg)
    animation: gift-rotate 0.5s linear alternate-reverse infinite
    display flex
    align-items center
    justify-content center
    .pendant-time
      position: absolute
      width: 100%
      color: #2E3A87
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2)
      font-size: 12px
      text-align: center
      bottom: 13px
      left: 0
      line-height: 14px
</style>
