<template>
  <div
    v-show="show"
    class="SignInModal"
    @click="handleClick"
  >
    <div
      class="content"
      :style="{animation: isIos ? 'none' : 'SignInModalShow 0.3s ease'}"
      @click.stop=""
    >
      <div class="text-center">
        <div class="flex items-center justify-center">
          <img
            :src="success ? SignInSuccessImg : SignInFailedImg"
            class="w-7 h-7 inline-block mr-2"
            alt=""
          >
          <span class="font-semibold text-base">{{ success?'打卡成功':'打卡失败' }}</span>
        </div>
        <div
          v-if="currentUserInfo.userName"
          class="text-center mt-5"
        >
          <img
            class="w-12 h-12 rounded-full border border-dark inline-block"
            :src="currentUserInfo.avatarUrl"
          >
          <div class="text-base font-semibold ml-2 mt-2">{{ currentUserInfo.userName || '' }}</div>
        </div>
        <div
          v-if="success"
          class="my-2 text-sm"
          style="color: #262626"
        >{{ successText || '若完成82全部打卡环节，可获得82限定薯办~' }}</div>
        <div
          v-if="success && finished"
          class="text-sm my-2 cursor-pointer"
          style="color: #137DBA"
          @click="goMyMedalPage"
        >点击查看我的纪念徽章 ></div>
        <div
          v-if="!success"
          class="my-2 text-sm"
          style="color: #262626"
        >请核对任务后再打卡哦</div>
        <div
          class="text-sm text-white inline-block rounded-3xl w-36 h-10 mt-3 leading-10 cursor-pointer bg-black"
          @click="handleClick"
        >好的</div>
      </div>
    </div>
  </div>
</template>
<script lang='ts'>
  import { defineComponent, computed } from 'vue'
  import { ApiEnv, getApiEnv, getUserInfoFromLocalStorage } from '@xhs/efficiency-tool'
  import { isIos } from 'shared/utils/isIos'
  import SignInSuccessImg from '~/assets/sign-in-success.png'
  import SignInFailedImg from '~/assets/sign-in-failed.png'

  export default defineComponent({
    name: 'SignInModal',
    props: {
      show: { type: Boolean, required: true, default: false },
      success: { type: Boolean, required: true, default: false },
      successText: { type: String, required: false, default: '' },
      finished: { type: Boolean, required: true, default: false },
    },
    emits: ['confirm'],
    setup(props, { emit }) {
      function handleClick() {
        emit('confirm')
      }

      const currentUserInfo = computed(() => {
        const userInfo = getUserInfoFromLocalStorage()
        return userInfo
      })

      return {
        SignInSuccessImg,
        SignInFailedImg,
        handleClick,
        isIos: isIos(),
        currentUserInfo,
        goMyMedalPage() {
          const url = getApiEnv() === ApiEnv.production ? 'https://annualparty.xiaohongshu.com/medalwall' : 'http://annualparty.sit.xiaohongshu.com/medalwall'
          window.location.href = url
        },
      }
    },
  })
</script>
<style lang='stylus' scoped>
.SignInModal
  position: fixed
  top: 0
  left: 0
  background-color: rgba(0,0,0,.7)
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  z-index 10000000
  .content
    background: #FFFFFF
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.06)
    border-radius: 16px
    width: 296px
    padding: 24px
</style>
<style lang="stylus">
@keyframes SignInModalShow
  0%{
    transform: scale(0.3)
  }
  100%{
    transform: scale(1)
  }
</style>
