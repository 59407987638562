<template>
  <div
    :class="[
      'chat-box',
      'mobile:min-w-full',
      'lg',
      'flex',isIpad?'isIpad':'',
      isPC?'isPC':'',
      inputFocus?'inputFocus':'',
      isMobile?'isMobile':'',
      isIOS?'isIOS':''
    ]"
  >
    <van-dialog
      v-model:show="showOfflineModal"
      class="kickout-modal"
      theme="round-button"
      :overlay-style="{position:'absolute'}"
      title="重复登录，账号被挤下线"
      message="无法发送和接收消息"
      confirm-button-text="重新连接"
      confirm-button-color="#2882FF"
      width="260px"
      @confirm="reConnect"
    />
    <div
      v-if="IMLoading || isLoading"
      class="flex flex-col items-center flex-1 justify-center p-4"
    >
      <a-skeleton
        avatar
        active
        :paragraph="{ rows: 5 }"
      />
      <a-skeleton
        avatar
        active
        :paragraph="{ rows: 5 }"
      />
    </div>
    <template v-else>
      <div
        v-if="imRoomId && !loginFailed"
        class="chat-box-header relative flex items-center justify-between bg-gray-brand"
        :style="{marginBottom:`${chatHeaderMB}px`}"
      >
        聊天互动
        <a-tooltip
          :overlay-style="{visibility:isMobile?'hidden':'visible',zIndex:100008}"
        >
          <template #title>设置公告</template>
          <span
            v-if="isManager"
            class="calendar_icon"
            @click="toggleNotice"
            v-html="noticeModal?CHAT_BOX_ICONS.CALENDAR_ACTIVE: CHAT_BOX_ICONS.CALENDAR"
          />
        </a-tooltip>
      </div>
      <top-message
        v-if="!IMLoading &&!isLoading && imRoomId"
        ref="topMessageRef"
        :is-manager="isManager"
        :announcement="noticeData.noticeContent"
        :list="topMessageList"
        :show-notice="showNotice"
        @cancel-topping="cancelTopping"
        @close-notice="noticeClose"
        @toggle-fold="toggleFold"
      />
      <div
        v-if="showJumpTip"
        class="jump-tip"
        @click="jumpToBottom"
      >
        <span
          class="mr-2"
          v-html="CHAT_BOX_ICONS.MESSAGE_TIP"
        />
        有新消息
      </div>
      <div class="relative flex flex-1 overflow-y-auto">
        <gift-banner
          ref="giftBannerRef"
          class="absolute top-2 left-0 w-full z-50 max-w-lg"
        />
        <chat-tip
          v-if="chatTipType || !imRoomId"
          :type="chatTipType"
          @retry="login"
        />
        <div
          v-else
          ref="chatListRef"
          class="chat-list flex-1"
          @scroll.passive="handleScroll"
          @touchmove.stop=""
        >
          <div
            v-if="loadingText && getHistoryFlag"
            class="loading-wrapper"
          >{{ loadingText }}</div>
          <message-item
            v-for="(item,index) in messageList"
            :key="item.messageId"
            :class="{'last-message-item':index === messageList.length - 1}"
            :room-id="roomId"
            :message-data="item"
            :is-manager="isManager"
            :idx="index"
            :banned-user-list="bannedUserList"
            :top-message-list="topMessageList"
            @mute="getBannedList"
            @top="getTopMessageData"
          />
        </div>
      </div>
      <div
        v-if="showEditor"
        class="px-4 flex items-center"
        :class="isIOS ? 'pb-6':'pb-3'"
      >
        <chat-editor
          ref="chatEditorRef"
          v-model:value="messageContent"
          class="main-editor"
          @confirm="handleSendMessage"
          @focus="inputFocus = true"
          @blur="inputFocus = false"
        />
        <!-- <praise-button
          ref="praiseButton"
          @send-gift="sendGift"
        /> -->
      </div>
      <Modal
        v-model:visible="noticeModal"
        :loading="noticeModalLoading"
        class="notice-modal"
        title="设置公告"
        :show-footer="!isMobile"
        @ok="handleSendNotice"
        @cancel="noticeModal = false"
      >
        <template #content>
          <chat-editor
            v-model:value="noticeContent"
            class="bg-gray-brand"
            :fixed-height="160"
            :max-length="50"
            :show-send-btn="false"
            @confirm="handleSendNotice"
          >
            <template
              v-if="isPC"
              #submitBtn
            >
              <span />
            </template>
            <template
              v-else
              #submitBtn
            >
              <a-button
                size="small"
                type="primary"
                class="notice-submit-btn"
                @click="handleSendNotice"
              >确定</a-button>
            </template>
          </chat-editor>
        </template>
      </Modal>
    </template>
  </div>
</template>
<script lang="ts">
  /* eslint-disable no-use-before-define */
  import {
    defineComponent,
    ref,
    Ref,
    watch,
    computed,
    watchEffect,
    PropType,
    nextTick,
    inject,
  } from 'vue'
  import {
    isMobile, isPC, isIpad, isIOS, isPCBrowser,
  } from 'shared/utils/browserCheck'
  import {
    BROADCAST_TYPE, defaultAvatar, GIFT_MAP, IM_EVENT_TYPE, MESSAGE_PRIORITY,
  } from 'shared/config/live.config'
  import { throttle, uniqBy } from 'lodash'
  import {
    cancelTopComment,
    getForbiddenList,
    noticePublish,
    queryNoticeInfo,
    queryTopCommentList,
  } from 'shared/services/live'
  import { BroadcastItem, MessageItem as MessageItemType } from 'shared/types/Live'
  import { IRoomInfo } from 'shared/types/IRoomInfo'
  import { LiveStatus } from 'shared/enums/LiveStatus'
  import useNoticeUpdate from 'shared/composables/useNoticeUpdate'
  // import PraiseButton from '~/components/PraiseButton/index.vue'
  import GiftBanner from '~/components/GiftBanner/index.vue'
  import { useChatRoom } from '~/composables/useChatRoom'
  import ChatEditor from '~/components/ChatEditor/index.vue'
  import showToast from '~/utils/showToast'
  import {
    CHAT_BOX_ICONS,
    LOADING_STATUS,
    MAX_MESSAGE_LENGTH,
    SCROLL_LOADING_TEXT,
    TIP_TYPE,
  } from './config'
  import MessageItem from './MessageItem.vue'
  import Modal from './Modal.vue'
  import ChatTip from './ChatTip.vue'
  import TopMessage from './TopMessage.vue'

  export default defineComponent({
    name: 'ChatBox',
    components: {
      MessageItem,
      ChatEditor,
      Modal,
      ChatTip,
      TopMessage,
      // PraiseButton,
      GiftBanner,
    },
    props: {
      isManager: { type: Boolean, required: false, default: false },
      isHost: { type: Boolean, required: false, default: false },
      imRoomId: { type: String, required: true, default: '' },
      isLoading: { type: Boolean, required: false, default: false },
      isRecord: { type: Boolean, required: true },
      detail: { type: Object as PropType<IRoomInfo>, required: true },
      roomId: { type: String, required: true },
      liveState: { type: String, required: false, default: '' },
    },
    emits: [
      IM_EVENT_TYPE.UPDATE_LIVE_STATE,
      IM_EVENT_TYPE.MEMBER_COUNT_CHANGE,
      IM_EVENT_TYPE.DANMU_MESSAGE,
      IM_EVENT_TYPE.PENDANT_CHANGE,
      IM_EVENT_TYPE.SENSITIVE_CHANGE,
    ],
    setup(props, { emit }) {
      const togglePendants:any = inject('togglePendants')

      // 播放器
      const playerRef:any = inject('player')

      // 消息输入框聚焦
      const inputFocus = ref<boolean>(false)

      const praiseButton = ref<any>(null)

      const giftBannerRef = ref<any>(null)

      // 消息内容
      const messageContent = ref<string>('')

      // 收到新消息
      const messageReceived = ref<boolean>(false)

      // 强制下线
      const isOffline = ref<boolean>(false)

      // 置顶消息折叠
      const isMessageFold = ref<boolean>(false)

      // 跳转到最新消息
      const showJumpTip = ref<boolean>(false)

      // 禁言用户列表
      const bannedUserList = ref<string[]>([])

      // 公告loading事件
      const noticeModalLoading = ref<boolean>(false)

      // 错误提示类型
      const chatTipType = ref<TIP_TYPE>()

      // 公告编辑内容
      const noticeContent = ref<string>('')

      // 接口返回的的公告信息
      const noticeData = ref<{noticeId:string; noticeContent:string}>({ noticeId: '', noticeContent: '' })

      // 置顶消息
      const topMessageList = ref<any[]>([])

      // 展示公告
      const showNotice = ref<boolean>(false)

      // 公告弹层容器
      const topMessageRef = ref<any>(null)

      // 消息列表容器
      const chatListRef = ref<HTMLElement | null>(null)

      // 聊天室header下外边距
      const chatHeaderMB = ref<number>(0)

      // 聊天编辑器容器
      const chatEditorRef = ref<any>(null)

      // 编辑公告modal
      const noticeModal = ref<boolean>(false)

      // 用户滚动标记
      const scrollFlag = ref<boolean>(false)

      // 消息列表加载状态
      const scrollLoadingStatus = ref<LOADING_STATUS>(LOADING_STATUS.SUCCESS)

      // 加载消息flag
      const getHistoryFlag = ref<boolean>(false)

      const showOfflineModal = computed(() => isOffline.value && showEditor.value)

      // 消息列表滚动到底部
      const scrollToBottom = () => {
        if (chatListRef.value) {
          const { offsetHeight, scrollHeight } = chatListRef.value
          chatListRef.value.scrollTop = scrollHeight - offsetHeight
        }
      }

      // 登录回调事件
      const afterLogin = () => {
        isOffline.value = false
        emit(IM_EVENT_TYPE.UPDATE_LIVE_STATE)
      }

      //  公告弹框
      const toggleNotice = () => {
        noticeModal.value = !noticeModal.value
        if (!isPCBrowser()) {
          setTimeout(() => {
            togglePendants(!noticeModal.value)
          })
        }
        if (noticeModal.value) {
          nextTick(() => {
            const noticeEditor:HTMLInputElement|null = document.querySelector('.notice-modal .chat-input')
            noticeEditor?.focus()
            setTimeout(() => {
              window.scrollTo({
                top: 10000,
                behavior: 'smooth',
              })
            }, 100)
          })
        }
      }

      // 获取公告
      async function getNoticeData() {
        const res = await queryNoticeInfo({ roomId: props.roomId })
        noticeData.value = res[0] || {}
        noticeContent.value = res[0]?.noticeContent || ''
        if (noticeData.value.noticeId) showNotice.value = true
      }

      // 公告展开收起
      function toggleFold(v:boolean) {
        isMessageFold.value = v
      }

      // 关闭公告
      function noticeClose() {
        showNotice.value = false
        chatHeaderMB.value = 0
      }

      // 获取置顶消息
      async function getTopMessageData() {
        const res = await queryTopCommentList({ roomId: props.roomId })
        topMessageList.value = (res || []).map((item:any) => {
          try {
            const text = JSON.parse(item.content || '{}').text
            return {
              ...item,
              text,
            }
          } catch (error) {
            console.log(error)
          }
          return {
            ...item,
            text: '',
          }
        })
      }

      // 公告版本更新
      useNoticeUpdate(BROADCAST_TYPE.NOTICE, getNoticeData)

      // 置顶版本更新
      useNoticeUpdate(BROADCAST_TYPE.TOPPING, getTopMessageData)

      // 判断是否展示跳转新消息tip
      function calcJumpBtn() {
        if (chatListRef.value) {
          const { scrollTop, offsetHeight, scrollHeight } = chatListRef.value
          const dis = scrollHeight - (scrollTop + offsetHeight)
          if (dis >= 20 && scrollFlag.value && messageReceived.value) {
            showJumpTip.value = true
          } else if (dis < 20) {
            scrollFlag.value = false
            showJumpTip.value = false
            messageReceived.value = false
          }
        } else {
          showJumpTip.value = false
        }
      }

      // 跳转到最新消息
      function jumpToBottom() {
        scrollFlag.value = false
        showJumpTip.value = false
        messageReceived.value = false
        nextTick(scrollToBottom)
      }

      // 点赞
      const praise = throttle((message:any) => {
        praiseButton.value?.sendGift?.(message.text)
      }, 500)

      const giftBanner = throttle((message:any) => {
        giftBannerRef.value.pushGift?.({
          id: message.messageId,
          avatar: message.avatar || defaultAvatar,
          name: message.userName,
          giftImage: GIFT_MAP[message.text].url,
          giftName: GIFT_MAP[message.text].name,
        })
      }, 1000)

      // 消息回调
      function messageCallback(messageList:any[]) {
        if (messageList.length) {
          messageList.forEach(message => {
            if (message?.isGift && giftBannerRef.value) {
              praise(message)
              giftBanner(message)
            } else {
              emit(IM_EVENT_TYPE.DANMU_MESSAGE, message)
              messageReceived.value = true
              calcJumpBtn()
            }
          })
        }
      }

      // 广播回调
      const broadcastCallback = (data:Ref<BroadcastItem>) => {
        if (data.value.broadcastType === BROADCAST_TYPE.NOTICE) {
          emit(IM_EVENT_TYPE.DANMU_MESSAGE, {
            text: data.value.broadcastValue,
            roomId: props.roomId,
            isNotice: true,
            priority: MESSAGE_PRIORITY.HIGH,
          })
          getNoticeData()
        }
        if (data.value.broadcastType === BROADCAST_TYPE.TOPPING) {
          getTopMessageData()
        }
        if (data.value.broadcastType === BROADCAST_TYPE.LIVE_STATE) {
          emit(IM_EVENT_TYPE.UPDATE_LIVE_STATE)
        }
        if (data.value.broadcastType === BROADCAST_TYPE.PENDANT_STATE) {
          emit(IM_EVENT_TYPE.PENDANT_CHANGE)
        }
        if (data.value.broadcastType === BROADCAST_TYPE.USER_FORBIDDEN) {
          getBannedList()
        }
        if (data.value.broadcastType === BROADCAST_TYPE.SWITCH_2_BACKUP) {
          const url = data.value.broadcastValue || ''
          if (/^http/i.test(url)) {
            window.location.href = url
          }
        }
        // 删除消息
        if (data.value.broadcastType === BROADCAST_TYPE.COMMENT_DELETE) {
          deleteMessage(data.value.broadcastValue || '')
        }
        // 敏感词消息
        if (data.value.broadcastType === BROADCAST_TYPE.SENSITIVE_CHANGE) {
          emit(IM_EVENT_TYPE.SENSITIVE_CHANGE, data.value.broadcastValue || '')
          const sensitiveWords = (data.value.broadcastValue || '').split(',').map(item => item.split('，')).flat()
          const danmu = playerRef?.value?.player?.player?.danmu
          const removeList = messageList.value.filter(item => sensitiveWords.find(word => item.text.toLocaleLowerCase().replace(/[^\u4e00-\u9fa5^\w]/gi, '').indexOf(word.toLowerCase()) > -1))
          removeList.forEach(item => {
            deleteMessage(item.messageId)
            danmu?.removeComment(item.messageId)
          })
        }
      }

      watchEffect(() => {
        if (props.roomId) {
          getNoticeData()
          getTopMessageData()
          getBannedList()
          // 防止部分历史数据消息丢失
          setTimeout(() => {
            getHistoryList({ roomId: props.roomId })
          }, 2000)
        }
      })

      function kickOutCallback() {
        isOffline.value = true
      }

      const {
        sendMessage,
        messageList,
        loading: IMLoading,
        // memberCount,
        getMessageList,
        loginFailed,
        login,
        joinGroup,
      } = useChatRoom({
        loginCallback: afterLogin,
        broadcastCallback,
        imRoomId: props.imRoomId,
        messageCallback,
        kickOutCallback,
        roomId: props.roomId,
      })

      const sendSelfGiftBanner = throttle((idx:number) => {
        const {
          name,
          userName,
          avatar,
          avatarUrl,
        } = JSON.parse(localStorage.getItem('userInfo') || '{}')
        if (giftBannerRef.value) {
          giftBannerRef.value.unshiftGift?.({
            id: Math.random().toString(36).slice(2, 8),
            avatar: avatarUrl || avatar || defaultAvatar,
            name: userName || name,
            giftImage: GIFT_MAP[idx].url,
            giftName: GIFT_MAP[idx].name,
          })
        }
      }, 1000)

      // 送礼物
      const sendGift = (idx:number) => {
        sendSelfGiftBanner(idx)
        sendMessage({
          text: idx,
          roomId: props.roomId,
          isAdmin: props.isManager,
          isHost: props.isHost,
          isGift: true,
        })
          .catch(() => {
            showToast('发送失败', 'warning')
          })
      }

      // IM重连
      function reConnect() {
        login()
      }

      // 删除消息
      function deleteMessage(id:string) {
        messageList.value = messageList.value.filter(t => t.messageId !== id)
      }

      // 取消置顶
      function cancelTopping(id:string = '') {
        cancelTopComment({ messageId: id, roomId: props.roomId }).then(() => {
          showToast('操作成功！', 'success')
        })
      }

      // 监听im房间id变化
      watchEffect(() => {
        if (props.imRoomId) {
          joinGroup(props.imRoomId)
        }
      })

      // 历史消息加载文案
      const loadingText = computed(() => SCROLL_LOADING_TEXT[scrollLoadingStatus.value])

      // 是否展示聊天输入框
      const showEditor = computed(() => {
        // 手机端展示公告弹框隐藏输入框
        if (noticeModal.value && isMobile) return false
        return !props.isRecord
          && props.imRoomId
          && !loginFailed.value
          && [
            LiveStatus.started,
            LiveStatus.suspend,
          ].includes(props.detail.status)
      })

      // 缺省状态改变
      watchEffect(() => {
        const isNoData = !messageList.value.length
        const isNotStarted = (props.detail && props.detail.status === LiveStatus.notStarted) || !props.imRoomId
        const isLoginError = loginFailed.value
        if (isNoData) {
          chatTipType.value = TIP_TYPE.NO_DATA
        }
        if (isNotStarted) {
          chatTipType.value = TIP_TYPE.NOT_STARTED
        }
        if (isLoginError) {
          chatTipType.value = TIP_TYPE.ERROR
        }
        if (!isNoData && !isNotStarted && !isLoginError) {
          chatTipType.value = undefined
        }
      })

      // // 监听IM人数变动
      // watch(memberCount, () => {
      //   console.log(`当前在线人数：${memberCount.value}`)
      //   emit(IM_EVENT_TYPE.MEMBER_COUNT_CHANGE, memberCount.value)
      // })

      // 监听消息列表长度
      watch([messageList, noticeData, topMessageList, isMessageFold], ([value], [oldValue]) => {
        if (
          value.length > oldValue.length
          && !scrollFlag.value
          && scrollLoadingStatus.value !== LOADING_STATUS.LOADING
        ) {
          nextTick(scrollToBottom)
          if (value.length >= MAX_MESSAGE_LENGTH && !props.isRecord) {
            messageList.value = [...messageList.value.slice(-MAX_MESSAGE_LENGTH)]
            scrollLoadingStatus.value = LOADING_STATUS.SUCCESS
          }
        }
        if (scrollFlag.value && value.length >= MAX_MESSAGE_LENGTH && value.length > oldValue.length && !props.isRecord && [LiveStatus.started, LiveStatus.suspend].includes(props.liveState as any)) {
          messageList.value = [...messageList.value.slice(0, MAX_MESSAGE_LENGTH)]
        }
        if (messageList.value.length <= 3 && (noticeData.value.noticeId || topMessageList.value.length)) {
          nextTick(() => {
            chatHeaderMB.value = 0
          })
        } else {
          chatHeaderMB.value = 0
        }
      })

      // 发布公告
      function handleSendNotice() {
        if (noticeModalLoading.value) return
        noticeModalLoading.value = true
        noticePublish({
          content: noticeContent.value,
          roomId: props.roomId,
          noticeId: noticeData.value.noticeId,
        }).then(() => {
          showToast('发布成功！', 'success')
          noticeModal.value = false
        }).finally(() => {
          setTimeout(() => {
            noticeModalLoading.value = false
          }, 200)
        })
      }

      // 禁止移动端

      // 获取禁言用户列表
      async function getBannedList() {
        const res = await getForbiddenList({
          roomId: props.roomId,
        })
        bannedUserList.value = res || []
      }
      // 获取历史数据
      const getHistoryList = throttle(async (params?:any) => {
        if (scrollLoadingStatus.value === LOADING_STATUS.NO_MORE) {
          return
        }
        scrollLoadingStatus.value = LOADING_STATUS.LOADING
        try {
          const res = await getMessageList(params)
          if (res.length) {
            scrollLoadingStatus.value = LOADING_STATUS.SUCCESS
            const l = res.map((t:any) => {
              try {
                return {
                  ...JSON.parse(t.commentContent || '{}'),
                  messageId: t.messageId,
                }
              } catch (error) {
                console.log(error)
              }
              return {}
            }).filter((item:any) => item.userId).reverse()
            messageList.value = uniqBy([...l, ...messageList.value], 'messageId')
            nextTick(() => {
              // if (chatListRef.value && l.length) {
              //   chatListRef.value.scrollTop = chatListRef.value.offsetHeight + 42
              // }
              if (params && chatListRef.value && l.length) {
                chatListRef.value.scrollTop = 200
              }
            })
          } else {
            if (messageList.value.length >= 10 && scrollFlag.value) {
              showToast('已加载全部～')
            }
            scrollLoadingStatus.value = LOADING_STATUS.NO_MORE
          }
        } catch (error) {
          scrollLoadingStatus.value = LOADING_STATUS.ERROR
        }
      }, 1000)

      // 滚动回调
      async function scrollCallback() {
        calcJumpBtn()
        if (scrollLoadingStatus.value === LOADING_STATUS.LOADING) return
        if (chatListRef.value && (chatListRef.value.scrollTop <= 10 && chatListRef.value.scrollTop > -2)) {
          getHistoryFlag.value = true
          const params = messageList.value.length
            ? { messageId: messageList.value[0]?.messageId, pageSize: 12, roomId: props.roomId }
            : { roomId: props.roomId }
          getHistoryList(params)
        }
      }

      // 恢复新消息自动定位功能
      // const resetScrollFlag = () => {
      //   scrollFlag.value = false
      // }

      function handleScroll() {
        if (chatListRef.value) {
          const { scrollTop, offsetHeight, scrollHeight } = chatListRef.value
          const dis = scrollHeight - (scrollTop + offsetHeight)

          if (dis >= 20) {
            scrollFlag.value = true
          }
        }
        scrollCallback()
      }

      //  发送消息
      function handleSendMessage(txt:string) {
        const value = messageContent.value || txt
        const {
          name,
          userName,
        } = JSON.parse(localStorage.getItem('userInfo') || '{}')
        nextTick(() => {
          const isBlank = messageContent.value && messageContent.value.replace(/\s/g, '') === ''
          if (isBlank) return showToast('不能发送空白消息')
          if (!messageContent.value && !value) return showToast('发送内容不能为空')
          scrollFlag.value = false
          scrollToBottom()
          messageContent.value = ''
          return sendMessage({
            text: value,
            roomId: props.roomId,
            isSelf: true,
            isAdmin: props.isManager,
            isHost: props.isHost,
            userName: userName || name,
          })
            .then((msg:MessageItemType) => {
              emit(IM_EVENT_TYPE.DANMU_MESSAGE, {
                text: value,
                roomId: props.roomId,
                isSelf: true,
                isAdmin: props.isManager,
                isHost: props.isHost,
                userName: userName || name,
                messageId: msg?.messageId,
              })
            })
            .catch((e:any) => {
              showToast('发送失败', 'warning')
              console.log(e)
            })
        })
      }

      if (/test=true/.test(window.location.href)) {
        (window as any).sendMessage = handleSendMessage
      }

      return {
        messageContent,
        chatListRef,
        handleSendMessage,
        messageList,
        IMLoading,
        chatEditorRef,
        CHAT_BOX_ICONS,
        isMobile,
        noticeModal,
        toggleNotice,
        noticeContent,
        handleSendNotice,
        isPC,
        handleScroll,
        loadingText,
        TIP_TYPE,
        noticeData,
        showNotice,
        topMessageList,
        isIpad,
        cancelTopping,
        topMessageRef,
        chatTipType,
        login,
        bannedUserList,
        loginFailed,
        scrollFlag,
        chatHeaderMB,
        getBannedList,
        noticeClose,
        showEditor,
        getTopMessageData,
        showJumpTip,
        noticeModalLoading,
        inputFocus,
        jumpToBottom,
        toggleFold,
        isIOS,
        showOfflineModal,
        reConnect,
        sendGift,
        getHistoryFlag,
        praiseButton,
        giftBannerRef,
      }
    },
  })
</script>
<style lang="stylus">
.chat-list
.top-message
  &::-webkit-scrollbar
    width: 0
    height: 0
.kickout-modal
  position: absolute
  background-color: #19191F
  .van-dialog
    &__header
    &__message
    &__footer
      background-color: #19191F
      color: #D5D7DD
</style>
<style lang="stylus" scoped>
@keyframes show-from-bottom-landscape
  0% {
    height: 0
    overflow: hidden
  }

  100% {
    height: 50vh
    overflow: hidden
  }
@media screen and (orientation landscape)
  .chat-box
    &.inputFocus
      &.isMobile
        overflow: hidden
    &.isMobile
      ::v-deep()
        .notice-modal
          .chat-editor
            &.emojiActive
              margin-bottom: 150px
            .chat-input
              height: 80px!important
        .chat-editor
          &.isMobile
            margin-bottom: 0
            .emoji-box
              position: fixed
              top: auto
              bottom: 0
              left: 0
              height: 50vh
              width: 100%
              grid-template-columns: repeat(15,1fr);
            .emoji-box-enter-active
              animation: show-from-bottom-landscape 0.2s cubic-bezier(0.01, 0.71, 0.21, 1.03)

            .emoji-box-leave-active
              animation: show-from-bottom-landscape 0.2s cubic-bezier(0.01, 0.71, 0.21, 1.03) reverse

.chat-box
  background-color: #0E0E11
  width: 100%
  height: 100%
  position: absolute
  display: flex
  z-index: 1000
  flex-direction: column
  ::v-deep()
    .van-overlay
      border-radius: 0 8px 8px 0
    .van-popup
      border-radius: 8px
      .van-button
        border-radius: 6px!important
    .top-message
      overflow-y: auto
      overflow-x: hidden
      // position: absolute
      // left: 0
      // top: 88px
      // z-index: 100
  &.inputFocus
    &.isMobile
      ::v-deep()
        .chat-editor
          .emoji-box
            &.emoji-box-enter-active
              animation: none!important
  &.isIOS
    .chat-list
      // flex-basis: 300px!important
      // flex-grow: 0!important
      -webkit-overflow-scrolling: touch
  &.isIpad
    ::v-deep()
      .top-message
        max-height: 200px
      .chat-editor
        .emoji-box
          .emoji-cell
            width: 40px
            height: 40px
  &.isPC
    ::v-deep()
      .notice-modal
        left: -320px
        .chat-editor
          border: 1px solid #2A2A32
          border-radius: 6px
          .emoji-box
            top: 120%
            left: -408px
            transform-origin: right center
  &-header
    height: 74px
    font-weight: 600
    font-size: 16px
    padding: 0 16px
    color: #D5D7DD
    flex-shrink: 0
    box-shadow: inset 0px -1px 0px rgba(42 42 50,0.6)
    .calendar_icon
      cursor: pointer
  .ant-spin
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
  .jump-tip
    position: absolute
    right: 0px
    bottom: 69px
    height: 34px
    width: 100px
    display: flex
    cursor: pointer
    align-items: center
    justify-content: center
    background-color: #454954
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
    border-radius: 6px 0px 0px 6px
    color: #D5D7DD
    z-index: 10
    opacity: 0.95
  .chat-list
    overflow-y: auto
    padding: 8px 16px 0
    position: relative
    margin-bottom: 10px
    .loading-wrapper
      height: 22px
      line-height: 22px
      color: #6C7079
      text-align: center
      font-size: 12px
  .main-editor
    // max-width: calc(100% - 56px)
    max-width: 100%
    // margin-right: 16px
    flex: 1
  &.isMobile
    .chat-box
      &-header
        height: 48px
      .chat-list
        overflow-y: scroll
    // .top-message
    //   top: 46px
    ::v-deep()
      .notice-modal
        position: fixed
        left: 0
        top: auto
        bottom: 0
        width: 100%
        .chat-editor
          flex-shrink: 0
          border: 1px solid #2A2A32
          border-radius: 6px
          transition: all 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03)
          .chat-opt-wrapper
            width: 100%
            .num-tip
              display: inline-flex
              margin-right: 0
              &:after
                content: ''
                width: 1px
                background-color: #454954
                margin: 2px 10px
            .notice-submit-btn
              margin-left: auto
</style>
