<template>
  <transition name="toggle-in">
    <div
      :class="{ 'chat-editor': true, isMobile, emojiActive: emojiModal, isIOS, isIpad,isSafari }"
      @click="wrapperClick"
      @keydown.enter="handleSendMessage"
    >
      <div class="overflow-hidden">
        <div
          ref="hiddenInput"
          class="hidden-content"
        >{{ value }}</div>
      </div>
      <div :class="['chat-input-wrapper', expand ? 'expand' : '']">
        <textarea
          ref="textInput"
          class="chat-input"
          :style="{ height: `${inputHeight}px` }"
          :value="value"
          placeholder="和大家一起聊聊吧～"
          :maxlength="maxLength"
          @focus="handleInputFocus"
          @click="handleClick"
          @input="handleInput"
          @change="handleChange"
          @compositionstart="isComposition = true"
          @compositionend="isComposition = false"
          @keydown.enter="e => e.preventDefault()"
          @blur="handleInputBlur"
        />
        <div
          ref="chatOptWrapperRef"
          class="chat-opt-wrapper"
        >
          <span :class="['num-tip', value.length >= maxLength ? 'error' : '']">{{ value.length }}/{{ maxLength }}</span>
          <span
            class="emoji-btn"
            @click.stop="handleEmojiIconClick"
            v-html="emojiModal ? EDITOR_ICONS.EMOJI_ACTIVE_BTN : EDITOR_ICONS.EMOJI_BTN"
          />
          <slot name="submitBtn">
            <transition name="toggle-chat-btns">
              <span
                v-if="!!value && showSendBtn"
                class="animate-box"
              >
                <span class="divider" />
                <span
                  :class="{ active: !!value, 'submit-button': true }"
                  @click="handleSendMessage"
                >发送</span>
              </span>
            </transition>
          </slot>
        </div>
      </div>
      <transition name="emoji-box">
        <div
          v-show="emojiModal"
          class="emoji-box"
          @click.stop="handleEmojiClick"
          v-html="emojiDomList"
        />
      </transition>
    </div>
  </transition>
</template>
<script lang="ts">
  import {
    defineComponent, ref, watchEffect, computed, nextTick, inject,
  } from 'vue'
  import { findKey, map, throttle } from 'lodash'
  import {
    isMobile, isIOS, isIpad, isPCBrowser, isSafari,
  } from 'shared/utils/browserCheck'
  import useBodyClick from 'shared/composables/useBodyClick'
  import showToast from 'shared/utils/showToast'
  import emojiMap, { emojiTextMap } from '~/config/emoji'
  import { EDITOR_ICONS } from './config'
  import getElementClient from './utils/getElementClient'

  export default defineComponent({
    props: {
      value: { type: String, required: true },
      showSendBtn: { type: Boolean, required: false, default: true },
      fixedHeight: { type: Number, required: false, default: 0 },
      maxLength: { type: Number, required: false, default: 100 },
    },
    emits: ['update:value', 'confirm', 'focus', 'blur'],
    setup(props, { emit }) {
      // 单行文本高度
      const HEIGHT = 22

      // 是否拼音
      const isComposition = ref<boolean>(false)

      const hiddenInput = ref(null)

      const expand = ref<boolean>(!!props.fixedHeight)

      const chatOptWrapperRef = ref(null)

      const textInput = ref<HTMLInputElement | null>(null)

      const inputRows = ref<number>(1)

      const emojiModal = ref<boolean>(false)

      const togglePendants:any = inject('togglePendants')

      const inputHeight = computed(() => (props.fixedHeight ? props.fixedHeight : (inputRows.value > 3 ? 3 : inputRows.value) * HEIGHT))

      const emojiDomList = map(
        emojiMap,
        (emoji, emojiID) => `<img class="emoji-cell" src="${emoji}" data-emoji="${emojiID}" />`,
      ).join('')

      function resetInput() {
        if (!props.fixedHeight) {
          expand.value = false
        }
        inputRows.value = 1
        emojiModal.value = false
        togglePendants(true)
      }

      function wrapperClick(e:MouseEvent) {
        e.stopPropagation()
      }

      function handleInput(e: any) {
        emit('update:value', e.target.value)
      }

      function handleChange(e: any) {
        const val = e.target.value?.trim()
        if (val && val.length > props.maxLength) return showToast(`最多输入${props.maxLength}个字`, 'warning')
        return emit('update:value', e.target.value)
      }

      // 发送消息
      function handleSendMessage() {
        if (isComposition.value) return
        if (props.value.length > props.maxLength) {
          showToast(`最多输入${props.maxLength}个字`, 'warning')
          return
        }
        emit('confirm')
        if (isMobile && !isIOS) {
          textInput.value?.blur()
        }
      }

      function handleEmojiIconClick() {
        emojiModal.value = !emojiModal.value
        if (!isPCBrowser()) {
          togglePendants(!emojiModal.value)
        }
      }

      function handleInputFocus() {
        emit('focus')
      }

      function handleInputBlur() {
        emit('blur')
      }

      function handleEmojiClick(e: any) {
        const target = e.target
        if (target?.classList.contains('emoji-cell') && textInput.value) {
          const { selectionStart, selectionEnd } = textInput.value
          const emojiText = findKey(emojiTextMap, t => t === target.dataset.emoji) || ''
          const startText = props.value.slice(0, selectionStart as number)
          const endText = props.value.slice(selectionEnd as number)
          const text = `${startText}${emojiText}${endText}`
          const index = (selectionStart || 0) + emojiText.length
          if (!isMobile) {
            textInput.value?.focus()
          }
          // ios设置光标位置会导致input focus 表情弹框会被关掉
          if (textInput.value && !isIOS) {
            // iso设置光标会触发软键盘,pc和android失焦后光标会跳到最后，需要手动设置光标位置
            textInput.value.setSelectionRange(index, index)
          }
          if (text.length > props.maxLength) {
            showToast(`最多输入${props.maxLength}个字`, 'warning')
          } else {
            emit('update:value', text)
          }
        }
      }

      function calcInputRows() {
        if (props.fixedHeight) {
          return
        }
        const { width: hiddenWidth } = getElementClient(hiddenInput.value)
        const { width: realWidth } = getElementClient(textInput.value)
        const { width: chatOptWrapperWidth } = getElementClient(chatOptWrapperRef.value)
        // 计算行数,计算时输入框宽度减去2，防止出现末尾不换行的情况
        inputRows.value = Math.ceil(hiddenWidth / (realWidth - 3)) || 1
        if (inputRows.value > 1 && !expand.value) {
          expand.value = true
          inputRows.value -= 1
        } else if (realWidth - chatOptWrapperWidth > hiddenWidth && expand.value) {
          expand.value = false
        }
      }

      function handleClick() {
        emojiModal.value = false
        togglePendants(true)
        calcInputRows()
      }

      useBodyClick(resetInput)

      watchEffect(() => {
        // 如果传了高度则不做计算处理
        if (props.fixedHeight) return
        if (!props.value) {
          resetInput()
          return
        }
        nextTick(() => {
          if (!props.fixedHeight) calcInputRows()
        })
      })

      return {
        handleChange,
        handleSendMessage: throttle(handleSendMessage, 1000, {
          leading: true,
          trailing: false,
        }),
        EDITOR_ICONS,
        hiddenInput,
        textInput,
        inputRows,
        inputHeight,
        chatOptWrapperRef,
        expand,
        emojiMap,
        emojiModal,
        handleEmojiClick,
        handleEmojiIconClick,
        handleInputFocus,
        handleInputBlur,
        calcInputRows,
        emojiDomList,
        isMobile,
        wrapperClick,
        isComposition,
        isIOS,
        isIpad,
        handleInput,
        handleClick,
        isSafari: isSafari(),
      }
    },
  })
</script>
<style lang="stylus" scoped>
@keyframes toggle-chat-btns
  0% {
    width: 0;
  }

  100% {
    width: 49px;
  }

@keyframes toggle-in
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

@keyframes bounce-in
  0% {
    transform: scale(0) translateY(-100%);
  }

  100% {
    transform: scale(1) translateY(-100%);
  }

@keyframes show-from-bottom
  0% {
    height: 0;
    overflow: hidden;
  }

  100% {
    height: 306px;
    overflow: hidden;
  }

.chat-editor
  position: relative
  top: 0
  font-size: 14px
  line-height: 22px
  background-color: #2A2A32
  border-radius: 6px
  padding: 9px 12px
  user-select: none
  z-index: 1999

  &.toggle-in-enter-active
    animation: toggle-in 2s cubic-bezier(0.01, 0.71, 0.21, 1.03)

  &.toggle-in-leave-active
    animation: toggle-in 2s cubic-bezier(0.01, 0.71, 0.21, 1.03) reverse

  &.isSafari
    ::v-deep()
      .emoji-box
        filter: none
  &.isMobile
    ::v-deep()
      .emoji-box
        background-color: #fff
        width: 100vw
        border-radius: 0
        top: 100%
        left: -16px
        padding: 8px 16px 16px
        transform: translateY(0)
        transform-origin: center top

        &.emoji-box-enter-active
          animation: none

        &.emoji-box-leave-active
          animation: none

        .emoji-cell
          width: 28px
          height: 28px
          cursor: pointer

    &.emojiActive
      margin-bottom: 282px
      max-width: 100%
  &.isIpad
    ::v-deep()
      .emoji-box
        grid-template-columns: repeat(12,1fr)
  .hidden-content
    visibility: hidden
    position: fixed
    left: 0
    top: 0
    white-space: nowrap
    letter-spacing: 1px

  .chat-input-wrapper
    display: flex
    flex-direction: row
    align-items: center

    &.expand
      flex-direction: column
      align-items: flex-start

      .chat-input
        width: 100%
        flex: auto
      .chat-opt-wrapper
        align-self: flex-end

    .chat-input
      flex: 1
      resize: none
      height: 22px
      color: #D5D7DD
      background-color: transparent
      word-break: break-all
      letter-spacing: 1px
      transition: all 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03)
      &::-webkit-scrollbar
        width: 0
        height: 0

    .chat-opt-wrapper
      display: flex
      align-items: center

      .emoji-btn
        align-self: center
        cursor: pointer

      .num-tip
        color: #6C7079
        font-size: 12px
        margin-right: 10px
        margin-left: 8px

        &.error
          color: #FF2442

      .divider
        width: 1px
        background-color: #454954
        margin: 0 10px
        height: 14px

      .animate-box
        overflow: hidden
        white-space: nowrap
        display: flex
        align-items: center

        .submit-button
          color: #454954

          &.active
            cursor: pointer
            color: #2882FF

      .toggle-chat-btns-enter-active
        animation: toggle-chat-btns 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03)

      .toggle-chat-btns-leave-active
        animation: toggle-chat-btns 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03) reverse

  ::v-deep()
    .emoji-box
      position: absolute
      top: -10px
      right: 0
      width: 375px
      height: 306px
      border-radius: 8px
      background-color: #19191F
      transform: translateY(-100%)
      transform-origin: right top
      display: grid
      padding: 16px
      grid-gap: 5px 20px
      grid-template-columns: repeat(7,1fr)
      justify-items: center
      place-items: center center
      z-index: 100
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.5))

      &.emoji-box-enter-active
        animation: bounce-in 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03)

      &.emoji-box-leave-active
        animation: bounce-in 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03) reverse

      .emoji-cell
        width: 28px
        height: 28px
        cursor: pointer
</style>
<style>
.isMobile.emojiActive+.PraiseButton{
  display: none
}

</style>
