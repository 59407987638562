.cancel-mute-button-icon
  display flex
  width: 156px
  height: 48px
.xgplayer-skin-default-ori.xgplayer-is-enter .xgplayer-enter
  display: flex !important
.xgplayer-skin-default-ori .xgplayer-enter
  justify-content center !important
  align-items center !important
.xgplayer-skin-default-ori .danmu-switch
  margin-right 6px
.xgplayer-skin-default-ori .xgplayer-loading
  transform: scale(.3)  !important
.xgplayer-skin-default-ori .xgplayer-enter-spinner
  display flex !important
  left 0 !important
  top 0 !important
  transform: scale(.3) !important
.xgplayer-skin-default-ori .xgplayer-definition
    margin-left -6px !important
    margin-right -20px !important
    ul
      left: -8px !important
    .name
      margin-top -26px !important
      margin-bottom 1px !important
      background: none !important
      font-weight: 700 !important
      font-size: 15px !important
      color white !important
      width 40px !important
.showWebFullPage
  .xgplayer-skin-default-ori .xgplayer-definition
    margin-left -8px !important
    margin-right -10px !important
    ul
      left: -8px !important
    .name
      margin-top -26px !important
      margin-bottom 1px !important
      background: none !important
      font-weight: 700 !important
      font-size: 15px !important
      color white !important
      width 40px !important
.xgplayer-skin-default-ori
  .xgplayer-toast
    display none !important
.xgplayer-skin-default-ori.xgplay-toast-show
  .xgplayer-toast
    pointer-events: none;
    width: 100%
    height: 100%
    display flex !important
    justify-content: center
    align-items: center
    position absolute
    top 0
    left 0


.xgplayer-skin-default-ori .xgplayer-controls
  background-image: linear-gradient(180deg,transparent,rgba(0,0,0,.7)) !important
.xgplayer-skin-default-ori .xgplayer-progress-outer 
  background: hsla(0,0%,100%,.5) !important
  box-shadow: 0px 0px 2px 0px hsla(0,0,0,.20) !important

.xgplayer-skin-default-ori .xgplayer-controls 
.xgplayer-skin-default-ori
  .xgplayer-volume
    margin-right: -2px
  .xgplayer-cancel-mute-button
    width: 100%
    height: 100%
    display flex !important
    justify-content: center
    align-items: center
    position absolute
    top 0
    left 0
    cursor pointer
    .cancel-mute-button-icon
        z-index 100
.xgplayer-skin-default-ori
  .xgplayer-live
    opacity 0
  .xgplayer-chat-icon
  .xgplayer-fullscreen-icon
    position relative
    order 10
    display block
    cursor pointer
    .xgplayer-icon
      margin-top 8px
      width 24px
      height 24px
      display block
    .xgplayer-tips 
      margin-left: -35px;
      .xgplayer-tip-chat-icon
        display: block;
    &:hover 
      opacity: 0.85;
      .xgplayer-tips 
        display: block;
  .xgplayer-fullscreen-icon
    .xgplayer-icon
      margin-top -2px
      display block
xg-pendants
  .xg-pendants-content
    z-index 1000

.videoWrapper-mobile
  @media screen and (orientation:landscape)
    height 100%
  #playerContainer
    // height 0
    @media screen and (orientation:landscape)
      height 100% !important
      padding-top 0 !important
