<template>
  <div
    class="prize-tag-box"
    :style="{
      height: `${height}px`
    }"
  >
    <div class="prize-tag-wrap">
      <div class="prize-tag text-center text-base font-semibold">{{ text }}</div>
    </div>
  </div>
</template>

<script lang='ts'>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'PrizeTag',

    props: {
      text: {
        type: String,
        default: '',
      },
      height: {
        type: Number,
        default: 26,
      },
    },

    setup() {
      return {}
    },
  })
</script>

<style lang="stylus" scoped>
  .prize-tag-box
    padding 1px
    border-radius 12px
    min-width 68px
    max-width 100%
    background-image linear-gradient(95.78deg, #FFF8EC 0%, #FFCC77 100%)
  .prize-tag-wrap
    width 100%
    height 100%
    border-radius 12px
    padding 5px 12px
    background-image linear-gradient(279.85deg, #EB3B23 55.29%, #FFCC77 100%)
    display flex
    align-items center
  .prize-tag
    background-image linear-gradient(95.78deg, #FFF8EC 0%, #FFCC77 100%)
    -webkit-background-clip text
    -webkit-text-fill-color transparent
    text-shadow 0px 2px 4px rgba(235, 59, 35, 0.2), inset 0px -0.2px 0px rgba(255, 248, 236, 0.6)
    width 100%
    white-space nowrap
    overflow hidden
    text-overflow ellipsis
    word-break break-all
</style>
