<template>
  <div class="prize-title whitespace-pre-wrap">{{ text }}</div>
</template>

<script lang='ts'>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'PrizeResultTitle',

    props: {
      text: {
        type: String,
        default: '',
      },
    },

    setup() {
      return {}
    },
  })
</script>

<style lang="stylus" scoped>
  .prize-title
    background-image linear-gradient(91.29deg, #FFCC77 0%, #EB3B23 50.36%)
    -webkit-background-clip text
    -webkit-text-fill-color transparent
    text-shadow 0px 2px 4px rgba(235, 59, 35, 0.2)
    font-size 28px
    line-height 36px
    font-weight 700
</style>
