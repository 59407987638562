<template>
  <div :class="{'chat-tip':true,isMobile}">
    <div class="tip-content">
      <span
        class="tip-content-img"
        v-html="TIP_DATA[type].icon"
      />
      <span class="tip-content-text whitespace-nowrap">{{ TIP_DATA[type].text }}</span>
      <a-button
        v-if="type === TIP_TYPE.ERROR"
        ghost
        class="mt-4"
        @click="retryLogin"
      >刷新</a-button>
    </div>
  </div>
</template>
<script lang='ts'>
  import { isMobile } from 'shared/utils/browserCheck'
  import { defineComponent, PropType } from 'vue'
  import { CHAT_BOX_ICONS, TIP_TYPE } from './config'

  export default defineComponent({
    name: 'ChatTip',
    props: {
      type: { type: String as PropType<TIP_TYPE>, required: true },
    },
    emits: ['retry'],
    setup(_, { emit }) {
      const TIP_DATA = {
        NO_DATA: {
          icon: CHAT_BOX_ICONS.NO_DATA,
          text: '暂无聊天互动内容～',
        },
        NOT_STARTED: {
          icon: CHAT_BOX_ICONS.NOT_STARTED,
          text: '直播开始后可以聊天互动～',
        },
        ERROR: {
          icon: CHAT_BOX_ICONS.ERROR,
          text: '连接异常，请刷新重试～',
        },
      }

      // 重新连接
      function retryLogin() {
        emit('retry')
      }

      return {
        TIP_DATA,
        TIP_TYPE,
        retryLogin,
        isMobile,
      }
    },
  })
</script>
<style lang='stylus' scoped>
.chat-tip
  overflow: hidden
  flex: 1
  display: flex
  align-items: center
  justify-content: center
  padding-bottom: 10px
  position: relative
  &.isMobile
    ::v-deep()
      .tip-content-img
        svg
          width: 80px
          height: 80px
  .tip-content
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    display: flex
    flex-direction: column
    align-items: center
    &-img
      margin-bottom: 16px
    &-text
      color: #6C7079
</style>
