<template>
  <a-layout
    class="live-container"
    style="min-height: 100vh; width: 100vw;"
  >
    <a-layout style="background: transparent;">
      <a-layout-header
        v-if="!isMobile&&!isLoginPage"
        style="background: transparent; line-height: 56px; height: 56px;"
        class="flex justify-between px-9"
      >
        <div class="left flex items-center">
          <!-- <img
            :src="LogoImage"
            class="w-36 h-7 flex"
            alt="小红书直播平台"
          > -->
        </div>
        <div class="right flex h-full items-center mr-6">
          <a-dropdown
            v-if="userName"
            trigger="click"
          >
            <template #overlay>
              <a-menu>
                <a-menu-item
                  @click="ssoLogoutHandler"
                >
                  退出登录
                </a-menu-item>
              </a-menu>
            </template>
            <div class="flex items-center cursor-pointer">
              <a-avatar
                :src="userAvatar"
                class="w-7 h-7 flex"
                style="border-radius: 50%; border: 1px solid #262730"
                :alt="userName"
              />
              <span class="text-gray-07 ml-2 mr-1 flex">{{ userName }}</span>
              <img
                class="w-3 h-3 flex"
                :src="ArrowIcon"
              >
            </div>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content
        style="overflow: auto; "
        :style="!isMobile ? 'height: calc(100vh - 56px)' : ''"
      >
        <div
          v-if="loading"
          class="loading-wrap h-60 w-full min-h-full flex items-center justify-center  "
        >
          <a-spin />
        </div>
        <router-view
          v-else
          :key="route.fullPath"
        />
      </a-layout-content>
    </a-layout>
    <dev-user-change v-if="getApiEnv() !== ApiEnv.production" />
  </a-layout>
</template>
<script lang="ts">
  import {
    defineComponent, onMounted, ref,
  } from 'vue'
  import { getApiEnv, ApiEnv, getUserInfoFromLocalStorage } from '@xhs/efficiency-tool'
  import { useRoute } from 'vue-router'
  import { defaultAvatar } from 'shared/config/live.config'
  import login, { ssoLogout } from '~/libs/efficiency-login'
  import DevUserChange from '~/components/DevUserChange.vue'
  import { getCurrentUserName } from '~/services/users'
  import { isIOS, isMobile } from '~/utils/browserCheck'
  import LogoImage from '~/assets/live-logo.png'
  import ArrowIcon from '~/assets/arrow.png'
  import { setLoginDone } from '~/utils/afterLoginPromise'
  import { isInLoginPage } from '~/utils/isInLoginPage'

  export default defineComponent({
    components: {
      DevUserChange,
    },
    setup() {
      const userAvatar = ref<string>('')
      const userName = ref('')

      const route = useRoute()
      const routeName = route.name as string
      const loading = ref(true)
      const isLoginPage = window.location.pathname === '/login'

      onMounted(async () => {
        if (isMobile && isIOS) {
          document.body.style.position = 'fixed'
          document.body.style.overflow = 'hidden'
          setTimeout(() => {
            window.scrollTo(0, 0)
          }, 100)
        }
        try {
          await login({
            ...{
              // dealWith401Self: true,
              wechatAgentId: '41', // 暂时不需要
              appType: 'forum',
              // noLoginUrls: ['/login'],
              dealLoginError: (errorMessage: string) => {
                setTimeout(() => {
                  throw new Error(`login  error: ${errorMessage}`)
                })
                return Promise.resolve(true)
              },
            },
            afterLogin() {
              loading.value = false
              setLoginDone()
            },
          })
        } catch (e) {
          console.log(e)
        }
        if (!isInLoginPage()) {
          userName.value = await getCurrentUserName()
          userAvatar.value = getUserInfoFromLocalStorage().avatarUrl || defaultAvatar
        }
      })

      return {
        isLoginPage,
        collapsed: ref<boolean>(true),
        selectedKeys: ref<string[]>([routeName].filter(Boolean)),
        userName,
        userAvatar,

        loading,
        route,
        getApiEnv,
        ApiEnv,
        isMobile,
        ssoLogoutHandler() {
          localStorage.clear()
          ssoLogout()
        },
        LogoImage,
        ArrowIcon,
      }
    },

  })
</script>
<style lang="stylus">
@media (max-width: 768px)
  html
  body
    max-width: 100vw
  .city-talk
    margin: 0 !important
    .ant-layout-content
      margin: 0 !important
.live-container
  background-size: cover
</style>
