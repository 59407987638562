<template>
  <div
    class="flex items-center justify-between px-2.5 md:px-6 py-4 bg-transparent h-full"
    :class="isWebFullScreen || isFullScreen || isMobile?'mobileOrFullScreen':'boxShadowBottom'"
  >
    <div class="flex items-center">
      <a-avatar
        :src="detail.avatar"
        class="w-10 h-10 flex-shrink-0"
        :alt="detail.avatar"
      />
      <div class="ml-2">
        <div class="flex items-center">
          <span
            class="font-semibold mr-1 text-white"
            :class="isMobile?'text-sm':'text-base'"
            :style="isWebFullScreen || isFullScreen ? { color: '#FFF' } : {}"
          >
            {{ detail.title }}
          </span>
          <span
            v-if="!isRecord && detail.status"
            :id="detail.status === LiveStatus.started?'headerIsLive':'headerIsNotLive'"
            class="flex items-center px-1 rounded flex-shrink-0"
            :class="{
              'bg-white bg-opacity-20': detail.status !== LiveStatus.started,
              'text-orange-brand': detail.status !== LiveStatus.started && detail.status !== LiveStatus.end,
              'text-white':[LiveStatus.started].includes(detail.status),
              'text-black':[LiveStatus.end].includes(detail.status),
              'bg-red-brand': detail.status === LiveStatus.started,
            }"
            style="padding: 0px 5px; height: 18px;"
          >
            <lottie
              v-if="detail.status === LiveStatus.started"
              :loop="true"
              class="w-3 h-3 md:w-3.5 md:h-3.5 mr-0.5"
              :animation-data="LivingAnimation"
            />
            <span class="font-medium text-2xs md:text-xs">
              {{ getLiveStatusName(detail.status) }}
            </span>
          </span>
          <span
            v-if="isRecord && detail.status"
            class="flex items-center px-1 rounded font-medium bg-white bg-opacity-20 md:text-xs text-2xs text-orange-brand"
            style="padding: 0px 5px; height: 18px;"
          >
            回放中
          </span>
        </div>
        <div
          v-if="isRecord || [LiveStatus.started,LiveStatus.suspend,LiveStatus.end].includes(detail.status)"
          class="text-2xs text-gray-01"
          :style="isWebFullScreen || isMobile || isFullScreen ? { color: '#FFF',lineHeight:'12px' } : {}"
        >
          {{ isRecord || [LiveStatus.end].includes(detail.status) ? '累计观看人数' : '观看人数' }}：{{ isRecord || [LiveStatus.end].includes(detail.status) ? userView : viewerNumber }}
        </div>
      </div>
    </div>
    <div
      class="inline-flex items-center flex-shrink-0"
    >
      <div
        v-if="detail.hasRecord&&!isRecord"
        class="video-btn flex items-center py-1.25 px-3 h-8 border border-solid rounded-md cursor-pointer hover:text-blue-brand"
        :class="isWebFullScreen || isMobile || isFullScreen?'text-white border-white':'border-gray-04 text-gray-02'"
        @click="watchPlayback"
      >
        <span
          class="w-4 h-4 video-icon"
        />
        <span class="ml-1.5">
          {{ !isMobile ? '观看回放' : '回放' }}
        </span>
      </div>
      <div
        v-if="!isMobile && !isWebFullScreen && !isFullScreen"
        class="link-btn flex items-center ml-3 py-1.25 px-3 h-8 border border-solid border-gray-04 rounded-md text-gray-02 hover:text-blue-brand cursor-pointer"
        @click="copyLink"
      >
        <span class="w-4 h-4 link-icon" />
        <span class="ml-1.5">
          复制链接
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { useRouter } from 'vue-router'
  import { IRoomInfo } from 'shared/types/IRoomInfo'
  import LivingAnimation from '~/assets/liveAnimation.json'
  import VideoIcon from '~/assets/video.png'
  import VideoWhiteIcon from '~/assets/video-white.png'
  import { isMobile } from '~/utils/browserCheck'
  import { getLiveStatusName, LiveStatus } from '~/enums/LiveStatus'
  import showToast from '~/utils/showToast'
  import { getNewPageUrl } from '~/utils/getNewPageUrl'
  import Lottie from './Lottie.vue'

  export default defineComponent({
    name: 'PlayerHeader',
    components: {
      Lottie,
    },
    props: {
      detail: {
        type: Object as PropType<IRoomInfo>,
        required: true,
      },
      viewerNumber: {
        type: Number,
        default: 0,
      },
      roomKey: {
        type: String,
        required: true,
      },
      isWebFullScreen: {
        type: Boolean,
        default: false,
      },
      isFullScreen: {
        type: Boolean,
        default: false,
      },
      isRecord: {
        type: Boolean,
        default: false,
      },
      userView: {
        type: Number,
        default: 0,
      },
    },
    setup(props) {
      const router = useRouter()
      const liveStatusString = getLiveStatusName(props.detail.status)

      return {
        LivingAnimation,
        isMobile,
        liveStatusString,
        VideoWhiteIcon,
        VideoIcon,
        LiveStatus,
        watchPlayback() {
          if (!props.isRecord) {
            router.push({
              name: 'Record',
              query: {
                roomKey: props.roomKey,
              },
            })
          }
        },
        getLiveStatusName,
        copyLink() {
          const link = getNewPageUrl(window.location.href)
          const title = props.detail !== null ? props.detail.title : ''
          const urlInput = document.createElement('input')
          urlInput.value = `${title ? `${title}，` : ''}戳这里观看👉🏻 ${link}`
          document.body.appendChild(urlInput)
          urlInput.select()
          document.execCommand('copy')
          showToast('已复制直播链接', 'success')
          urlInput.remove()
        },
      }
    },
  })
</script>

<style lang="stylus" scoped>
  .link-icon
    display inline-block
    background-repeat no-repeat
    background-size 100%
    background-image url('../assets/default-link.png')
  .link-btn:hover .link-icon
    background-image url('../assets/active-link.png')
  .video-icon
    display inline-block
    background-repeat no-repeat
    background-size 100%
    background-image url('../assets/video.png')
  .video-btn:hover .video-icon
    background-image url('../assets/video-blue.svg')
  .mobileOrFullScreen
    .video-icon
      background-image url('../assets/video-white.png')
  .boxShadowBottom
    box-shadow: inset 0px -1px 0px rgb(42 42 50 / 60%)
</style>
