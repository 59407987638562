.ant-dropdown-menu
  background #19191F
  box-shadow 0px 4px 20px rgba(0, 0, 0, 0.08)
  border-radius 6px
  padding 8px
.ant-dropdown-menu-item
  color #D5D7DD
  padding 5px 8px
  &:hover
    background #2A2A32
    border-radius 6px
