<template>
  <div
    ref="messageItem"
    :class="[
      'message-item',
      messageData.isTip?'message-item-tip':'',
      isManager?'isManager':'',
      operateModal?'active':'',
    ]"
  >
    <div
      v-if="messageData.isTip"
      class="group-tip text-xs"
    ><span class="text-red-600">系统提示：</span>欢迎{{ messageData.userName }}加入直播间</div>
    <template v-else>
      <img
        class="message-item-avatar"
        :src="messageData.avatar"
        alt="用户头像"
      >
      <div class="message-item-title">
        <div class="message-item-title-name">
          {{ messageData.userName }}
          <a-tooltip
            v-if="isManager"
            placement="left"
            trigger="click"
            :mouse-leave-delay="0"
            overlay-class-name="chat-box-operate-tip"
            :visible="showTooltip"
          >
            <template #title>
              <div
                class="chat-box-operate-item"
                @click="handleTop"
              >
                <span
                  style="marginRight:10px"
                  v-html="isTopped? CHAT_BOX_ICONS.CANCEL_TOP_ICON:CHAT_BOX_ICONS.TOP_ICON"
                />
                <span>{{ isTopped?'取消精选':'精选评论' }}</span>
              </div>
              <a-popconfirm
                :overlay-class-name="`chat-box-operate-confirm-tip ${isMobile?'isMobile':''} ${isIpad?'isIpad':''}`"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelete"
              >
                <template #title>
                  <div
                    class="text-center"
                  >
                    <div style="font-size:16px">删除该条消息？</div>
                    <div>删除后，该条消息将在聊天界面消失且无法找回</div>
                  </div>
                </template>
                <template #icon>
                  <span />
                </template>
                <div
                  class="chat-box-operate-item"
                >
                  <span
                    style="marginRight:10px"
                    v-html="CHAT_BOX_ICONS.DELETE_ICON"
                  />
                  <span>删除</span>
                </div>
              </a-popconfirm>
              <a-popconfirm
                :overlay-class-name="`chat-box-operate-confirm-tip ${isMobile?'isMobile':''} ${isIpad?'isIpad':''}`"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleMute"
              >
                <template #title>
                  <div class="text-center">
                    <div style="font-size:16px">{{ isBanned?'解禁该用户？':'禁言该用户？' }}</div>
                    <div>{{ isBanned?'解禁后，其他用户将能继续看到该用户发的消息':'禁言后，该用户将无法继续参与本次直播互动' }}</div>
                  </div>
                </template>
                <template #icon>
                  <span />
                </template>
                <div
                  class="chat-box-operate-item"
                >
                  <span
                    style="marginRight:10px"
                    v-html="isBanned?CHAT_BOX_ICONS.MUTED_ICON:CHAT_BOX_ICONS.MUTE_ICON"
                  />
                  <span>{{ isBanned?'取消禁言':'禁言' }}</span>
                </div>
              </a-popconfirm>
            </template>
            <span
              v-if="isManager"
              class="opt-btn"
              @click="handleOptClick"
            >...</span>
          </a-tooltip>
        </div>
      </div>
      <div
        class="message-item-content"
        v-html="transChatMessage(messageData.text)"
      />
    </template>
  </div>

</template>
<script lang="ts">
  import {
    defineComponent, PropType, ref, watchEffect,
  } from 'vue'
  import { MessageItem } from 'shared/types/Live'
  import {
    cancelTopComment,
    commentDelete, forbiddenUser, removeBannedUser, setTopComment,
  } from 'shared/services/live'
  import showToast from 'shared/utils/showToast'
  import { isMobile, isIpad } from 'shared/utils/browserCheck'
  import useBodyClick from 'shared/composables/useBodyClick'
  import { transChatMessage } from '~/utils/transChatMessage'
  import { ADMIN_OPERATE_TYPE, CHAT_BOX_ICONS } from './config'

  export default defineComponent({
    name: 'MessageItem',
    props: {
      messageData: { type: Object as PropType<MessageItem>, required: true },
      isManager: { type: Boolean, required: false, default: true },
      idx: { type: Number, required: true },
      roomId: { type: String, required: true },
      bannedUserList: { type: Array, required: true, default: () => ([]) },
      topMessageList: { type: Array, required: true, default: () => ([]) },
    },
    emits: ['mute', 'top'],
    setup(props, { emit }) {
      const operateModal = ref<boolean>(false)

      const messageItem = ref<HTMLElement | null>(null)

      const showTooltip = ref<boolean>(false)

      const isBanned = ref<boolean>(false)

      const isTopped = ref<boolean>(false)

      function handleBodyClick() {
        showTooltip.value = false
      }

      useBodyClick(handleBodyClick)

      function handleOptClick() {
        if (!showTooltip.value) {
          setTimeout(() => {
            showTooltip.value = true
          })
        }
      }

      watchEffect(() => {
        if (props.bannedUserList.find((item:any) => `2_${item.userId}` === props.messageData.userId)) {
          isBanned.value = true
        } else {
          isBanned.value = false
        }
        if (props.topMessageList.find((item:any) => item.messageId === props.messageData.messageId)) {
          isTopped.value = true
        } else {
          isTopped.value = false
        }
      })

      // 删除
      function handleDelete() {
        commentDelete({ messageId: props.messageData.messageId, roomId: props.roomId }).then(() => {
          showToast('删除成功！', 'success')
          if (messageItem.value) {
            messageItem.value.click()
          }
        })
      }

      // 设置置顶
      function handleTop() {
        if (props.topMessageList.length === 3 && !isTopped.value) {
          return showToast('最多置顶三条消息！', 'warning')
        }
        const fn = isTopped.value ? cancelTopComment : setTopComment
        return fn({ messageId: props.messageData.messageId, roomId: props.roomId }).then(() => {
          showToast('操作成功！', 'success')
          document.body.click()
          emit('top')
        })
      }

      // 禁言用户
      function handleMute() {
        const fn = isBanned.value ? removeBannedUser : forbiddenUser
        fn({ userId: props.messageData.userId, roomId: props.roomId }).then(() => {
          showToast('操作成功！', 'success')
          emit('mute')
        })
      }

      return {
        transChatMessage,
        operateModal,
        CHAT_BOX_ICONS,
        ADMIN_OPERATE_TYPE,
        handleMute,
        handleTop,
        handleDelete,
        messageItem,
        isBanned,
        isTopped,
        isMobile,
        isIpad,
        showTooltip,
        handleOptClick,
      }
    },
  })
</script>
<style lang="stylus" scoped>
  .message-item
    padding-left: 36px
    margin-bottom: 10px
    border: 1px solid transparent
    border-radius: 8px
    position: relative
    &:last-child
      margin-bottom: 10px
    &.isManager
        .message-item-title-name
          .opt-btn
            position: absolute
            right: 0
            top: -4px
            line-height: 1
            color: #6C7079
            font-size: 16px
            font-weight: 500
            letter-spacing: 1px
            cursor: pointer
            &:hover
              color: #2882FF
    &.active
      .message-item-title-name
        .opt-btn
            color: #2882FF
    &-tip
      padding-left: 6px
      background-color: transparent
      .group-tip
        font-size: 12px
        color: #6C7079
    &-title
      font-size: 12px
      color: #6C7079
    &-content
      font-size: 14px
      color: #D5D7DD
      word-break: break-word
    &-avatar
      width: 24px
      height: 24px
      border-radius: 50%
      position: absolute
      left: 2px
      top: 2px
</style>
<style lang="stylus">
.chat-box-operate-tip
  z-index 100008
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.5))
  .ant-tooltip-content
    width: 120px
    height: 110px
    border-radius: 6px
    background: #19191F
    padding: 8px
    .ant-tooltip-arrow
      display: none
    .ant-tooltip-inner
      background-color: transparent
      box-shadow: none
      padding: 0
      .chat-box-operate-item
        display: flex
        border-radius: 6px
        padding: 4px 8px
        cursor: pointer
        align-items: center
        &:hover
          background-color: #2A2A32
.chat-box-operate-confirm-tip
  z-index: 100008
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.5))
  &.isMobile
    position: fixed!important
    left: 36%!important
  &.isIpad
    left: 38vw!important
  .ant-popover-content
    width: 260px
    margin-left: -80px
    border-radius: 6px
    background: #19191F
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)
    .ant-popover-arrow
      display: none
    .ant-popover-inner
      background-color: transparent
      box-shadow: none
      padding: 0
      text-align: center
      .ant-popover-message-title
        padding: 0
        color: #D5D7DD
      .ant-popover-buttons
        text-align: center
        .ant-btn
          margin-right: 12px
          width: 80px
          height: 32px
          &:first-child
            border: 1px solid #454954;
            color: #93979F
            background-color: #19191F
            border-radius: 6px
          &:last-child
            background-color: #2882FF
            border-radius: 6px
</style>
