<template>
  <div
    id="live-layout"
    class="mx-auto"
    :class="{
      'main-container': !isMobile && !isWebFullScreen && !isFullScreen,
      'main-container-in-mobile': isMobile || isWebFullScreen || isFullScreen,
      'main-container-isWebFullScreen': isWebFullScreen && !isMobile,
      'main-container-isFullScreen': isFullScreen && !isMobile,
      isIpad,
      isIOS
    }"
  >
    <slot />
    <div
      :class="{
        'player-main-container': !isMobile && !isWebFullScreen && !isFullScreen,
        'player-main-container-in-mobile': isMobile || isWebFullScreen,
        'player-main-container-isWebFullScreen': isWebFullScreen && !isMobile,
        'player-main-container-isFullScreen': isFullScreen && !isMobile,
      }"
    >
      <div
        :class="{
          'player-header': !isMobile,
          'player-header-in-mobile': isMobile || isWebFullScreen || isFullScreen,
        }"
      >
        <slot name="header" />
      </div>
      <div
        class="relative"
        :class="{
          'player-container': !isMobile && !isWebFullScreen && !isFullScreen,
          'player-container-in-mobile': isMobile || isWebFullScreen || isWebFullScreen,
          'player-container-isWebFullScreen': isWebFullScreen && !isMobile,
          'player-container-isFullscreen': isFullScreen && !isMobile,
        }"
      >
        <slot name="player" />
      </div>
    </div>
    <div
      :class="{
        'chat-container': !isMobile && !isWebFullScreen,
        'chat-container-in-mobile': isMobile || isWebFullScreen,
        'chat-container-isWebFullScreen': isWebFullScreen && !isMobile,
        'chat-container-isFullScreen': isFullScreen && !isMobile,
      }"
    >
      <slot name="chat" />
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { isMobile, isIpad, isIOS } from '~/utils/browserCheck'

  export default defineComponent({
    name: 'Layout',
    props: {
      isWebFullScreen: {
        type: Boolean,
        default: false,
      },
      isFullScreen: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      return {
        isMobile,
        isIpad,
        isIOS,
      }
    },
  })
</script>

<style lang="stylus" scoped>
  // PC端 样式
  .main-container
    min-width 1024px
    display flex
    justify-content center
    border-radius 8px
    max-width: 1280px
    border: 3px solid #21222D
    overflow: hidden
    position: relative
  .player-main-container
    display flex
    flex 1
    width 100%
    flex-direction: column
    @media screen and (min-width: 1440px)
      width 920px
  .player-container
    display: flex
    flex: 1
    min-width 600px
    max-width 920px
    height 0
    padding-bottom 56.25%
    position relative
    @media screen and (min-width: 1440px)
      width 920px
  .player-header
    height 74px
    &.player-header-in-mobile
      pointer-events: none
  .chat-container
    min-width 264px
    width 320px
    flex-shrink 0
    border-radius: 0 0.5rem 0.5rem 0
    background-color: #0e0e11
    position relative
    z-index 100006
    @media screen and (max-width: 1024px)
      width 264px

  // 移动端样式
  .main-container-in-mobile
    display flex
    flex-direction column
    height 100vh
    min-width 100vw
    // 手机/平板横屏
    &.isIpad
      height: calc(100vh - 64px)
    @media screen and (orientation:landscape)
      flex-direction row
      align-items center
  .player-main-container-in-mobile
    position relative
    width 100%
    @media screen and (orientation:landscape)
      height 100%
  .player-container-in-mobile
    position relative
    width 100%
    height 0
    padding-bottom 56.25%
    @media screen and (orientation:landscape)
      height 100%
      padding-bottom 0
  .player-header-in-mobile
    position absolute
    width 100%
    height 52px
    z-index 1000
    ::v-deep()
      .ant-avatar
        width: 32px!important
        height: 32px!important
    @media screen and (min-width: 960px)
      height 74px
  .chat-container-in-mobile
    width 100%
    height 100%
    position relative
    // 手机/平板横屏
    @media screen and (orientation:landscape)
      width 320px
      flex-shrink 0
      @media screen and (max-width: 1024px)
        width 264px
  // PC端网页全屏
  .player-main-container-isWebFullScreen
    height 100%
  .player-container-isWebFullScreen
    height 100%
    padding-bottom 0
  .main-container-isWebFullScreen
    flex-direction row
    align-items center
    position absolute
    top 0
    background #FFF
  .chat-container-isWebFullScreen
    width 320px
    flex-shrink 0
    z-index 100006
    @media screen and (max-width: 1024px)
      width 264px
  // PC端全屏
  .main-container-isFullScreen
    flex-direction row
    align-items center
    position absolute
    top 0
    background #000
    >>>.chat-container-isFullScreen
      display: none
  .player-main-container-isFullScreen
    height 100%
    width: 100%
    .player-container-isFullscreen
      height: 100%
      >>>#playerContainer
        width: 100%!important
        padding-top: 0!important
        height: 100%!important
</style>
