<template>
  <div>
    <a-steps
      :current="current"
      type="navigation"
    >
      <a-step
        v-for="item in steps"
        :key="item.title"
        :title="item.title"
      />
    </a-steps>
    <div class="steps-action">
      <a-button
        v-if="current === 0"
        @click="onPrepare"
      >准备开播</a-button>
      <a-button
        v-if="current === 1"
        @click="onOpen"
      >开播</a-button>
      <a-button
        v-if="current === 2"
        @click="onClose"
      >关闭直播</a-button>
      <a-button
        v-if="current === 2"
        @click="joinRoom"
      >
        进入房间
      </a-button>
    </div>
    <div class="steps-content">
      <div v-if="roomId">房间号：{{ roomId }}</div>
      <div v-if="pushUrl">推流地址：{{ pushUrl }}</div>
      <div v-if="pullInfo">拉流信息：{{ pullInfo }}</div>
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { createLiveRoom, startLive, stopLive } from './services'

  export default defineComponent({
    setup() {
      const route = useRoute()
      const router = useRouter()
      const roomId = ref<string>('')
      const pushUrl = ref<string>('')
      const steps = ref([
        {
          title: '开播准备',
        },
        {
          title: '开播',
        },
        {
          title: '直播中',
        },
        {
          title: '结束',
        },
      ])
      const current = ref<number>(Number(route.query?.currentStep || 0))
      roomId.value = (route.query?.roomId || '') as string
      pushUrl.value = ((route.query?.pushUrl as string) || '')
      const changeUrl = (currentStep: number) => {
        router.push({
          name: 'Live',
          query: {
            currentStep,
            roomId: roomId.value,
            pushUrl: (pushUrl.value),
          },
        })
      }
      return {
        current,
        steps,
        roomId,
        pushUrl,
        async onPrepare() {
          const res = await createLiveRoom() as any
          roomId.value = res?.roomId
          pushUrl.value = res?.pushUrl
          localStorage.setItem('streamInfo', JSON.stringify(res?.pullInfo || ''))
          changeUrl(1)
        },
        async onOpen() {
          await startLive(roomId.value)
          changeUrl(2)
        },
        async onClose() {
          await stopLive(roomId.value)
          changeUrl(3)
        },
        joinRoom() {
          window.open(`/homepage?roomId=${roomId.value}`, '_blank')
        },
      }
    },
  })
</script>

<style lang="stylus" scoped>
  .steps-action
    margin 20px 0px
</style>
