<template>
  <div
    v-if="isDEV"
    class="change-wrap"
  >
    <div
      class="btn-box"
      @click="show = true"
    >
      <van-icon name="exchange" />
    </div>
    <van-dialog
      v-model:show="show"
      title="切换用户"
      show-cancel-button
      :show-confirm-button="false"
    >
      <van-search
        v-model="value"
        @search="onSearch"
      />
      <div class="list-wrap">
        <van-cell
          v-for="row in list"
          :key="row.value"
          class="list-cell"
          @click="testLogin(row.value)"
        >
          {{ row.label }}
        </van-cell>
      </div>
    </van-dialog>
  </div>
</template>

<script lang='ts'>
  import { defineComponent, ref, unref } from 'vue'
  import {
    Toast, Icon, Dialog, Search, Cell,
  } from 'vant'
  import { ApiEnv, getApiEnv } from '@xhs/efficiency-tool'
  import { getUsers, getUsersByEmail, queryUserInfo } from '~/services/users'

  function getUserList(userName: string) {
    return getUsers(userName, 5)
      .then(data => ((data || []) as any[]).map(item => ({
        label: `${item.redName || item.userName}/${item.department}`,
        value: item.email,
      })))
  }

  function testLogin(userEmail: string) {
    return getUsersByEmail(userEmail)
      .then(async (res: any) => {
        if (res !== null) {
          localStorage.setItem('auth-token', res.token)
          localStorage.setItem('userInfo', JSON.stringify(res))
          await queryUserInfo()
          const currentHref = window.location.href
          window.location.href = currentHref
        } else {
          Toast('切换失败，请重试～')
        }
      })
  }

  export default defineComponent({
    name: 'DevUserChange',

    components: {
      VanIcon: Icon,
      VanDialog: Dialog.Component,
      VanSearch: Search,
      VanCell: Cell,
    },

    setup() {
      const isDEV = getApiEnv() !== ApiEnv.production
      const show = ref(false)
      const value = ref('')
      const list = ref<any>([])

      async function onSearch() {
        list.value = await getUserList(unref(value))
      }

      return {
        show,
        value,
        list,
        isDEV,
        onSearch,
        testLogin,
      }
    },
  })
</script>

<style lang="stylus" scoped>
.btn-box
  position fixed
  color #fff
  width 40px
  height 40px
  display flex
  border-radius 50%
  background rgba(58, 100, 255, .9)
  align-items center
  justify-content center
  font-size 20px
  bottom 150px
  right 10px
  z-index 10
</style>
