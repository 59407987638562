@import './antv.styl'
@import './vant.styl'

@import './player.styl';

* {
  font-family: 'PingFang SC', 'SF Pro Text', 'Microsoft YaHei', Helvetica, Arial, '-apple-system','Source Han Sans SC','Noto Sans CJK SC','WenQuanYi Micro Hei', sans-serif;
}

body
    background-color: #19191f

input::-webkit-input-placeholder
textarea::-webkit-input-placeholder
    color: #454954

.award-modal
  z-index: 100007!important
  &+.van-popup
    z-index: 100008!important

.hairline
.hairline-bottom
.hairline-top
  position relative
  &::after
    content ''
    box-sizing border-box
    display block
    position absolute
    top 0
    left 0
    width 200%
    height 200%
    transform scale(.5)
    transform-origin left top
    pointer-events none
.hairline
  &::after
    border 1PX solid #e3e3e3
.hairline-bottom
  &::after
    border-bottom 1PX solid #e3e3e3
.hairline-top
  &::after
    border-top 1PX solid #e3e3e3

.fade-enter-active, .fade-leave-active
  transition opacity 0.3s ease
.fade-enter, .fade-leave-to
  opacity 0
.ql-overflow-hidden
  overflow hidden
.van-image-preview__image img
  user-select none

em.target
  color #ff2442
  font-style: normal
  
/* 评论回复 展示区域及编辑器 限制图片宽度*/
.isNotMobile .CommentDetail img.resize-gallery-image
  max-width 400px
  margin-top 4px
  margin-bottom 4px
  display: block
  margin-left 0
.isNotMobile .CommentDetail a.js-gallery
  display: block
.isNotMobile .postPage img.ql-image-cell
  max-width 400px
  margin-top 4px
  margin-bottom 4px
  display: block
  margin-left 0
.isMobile .CommentDetail img.m-resize-gallery-image
  max-width 160px
  margin-top 4px
  margin-bottom 4px
  display: block
  margin-left 0

.isMobile .ReplyInComment img.m-resize-gallery-image
  max-width 160px
  margin-top 4px
  margin-bottom 4px
  display: block
  margin-left 0
.comment-wrap div.ql-editor img.ql-image-cell
  max-width 160px
  margin-top 4px
  margin-bottom 4px
  display: block
  margin-left 0

.isAndroid .font-medium
  font-weight 700
.isAndroid .font-semibold
  font-weight 700

.ant-transfer-operation button
  display: flex !important
  align-items: center !important
  justify-content: center !important
  font-size 16px !important
button.ant-btn-loading span.anticon
  vertical-align: 0px 


.ant-message-notice-content
    background: #000000
    opacity: 0.9
    color: #fff
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08)
    border-radius: 8px
    .ant-message-custom-content
        display flex
        align-items center

@keyframes customZoomIn
  from
    -webkit-transform translate3d(-50%,-50%,0) scale3d(0.3, 0.3, 0.3)
    transform translate3d(-50%,-50%,0) scale3d(0.3, 0.3, 0.3)
  80%
    -webkit-transform translate3d(-50%,-50%,0) scale3d(1.02, 1.02, 1.02)
    transform translate3d(-50%,-50%,0) scale3d(1.02, 1.02, 1.02)
  100%
    -webkit-transform translate3d(-50%,-50%,0) scale3d(1, 1, 1)
    transform translate3d(-50%,-50%,0) scale3d(1, 1, 1)

.custom-animation-zoom-in
  animation customZoomIn 0.6s
