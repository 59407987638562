<template>
  <van-config-provider
    :theme-vars="{
      dialogBorderRadius: '24px',
    }"
  >
    <van-dialog
      v-model:show="show"
      :show-confirm-button="false"
      :width="modalWidth"
      overlay-class="award-modal"
      style="background: transparent; top: 50%;"
      class="custom-animation-zoom-in"
    >
      <div
        class="content-box"
        :style="{ width: `${modalWidth}px` }"
      >
        <div
          class="content text-dark relative bg-card"
        >
          <div
            class="absolute right-4 top-4 cursor-pointer"
            @click="handleClose"
          >
            <img
              class="w-5 h-5"
              :src="CloseIcon"
            >
          </div>
          <div class="flex justify-center">
            <prize-result-title
              class="text-center"
              :text="modalTitle"
            />
          </div>
          <template v-if="isWin">
            <div
              v-if="!isLandscape"
              class="text-center"
            >
              <div
                v-if="currentUserInfo.userName"
                class="flex items-center justify-center my-4"
              >
                <img
                  class="w-7 h-7 rounded-full border border-dark"
                  :src="currentUserInfo.avatarUrl"
                >
                <span class="text-base font-semibold ml-2">{{ currentUserInfo.userName || '' }}</span>
              </div>
              <div class="prize-wrapper mb-6">
                <div class="prize-box flex items-center justify-center">
                  <img
                    class="prize-img rounded object-cover"
                    :src="detail.assetsPicUrl"
                  >
                  <prize-tag
                    style="position: absolute; bottom: -12px;"
                    :text="detail.awardsName"
                  />
                </div>
              </div>
              <div class="text-base font-semibold mt-6">{{ detail.assetsName }}</div>
              <div class="text-dark text-xs mt-11">
                奖品会在活动结束后统一发放哦
              </div>
            </div>
            <div
              v-else
              class="flex my-5 mx-8"
            >
              <div class="prize-wrapper-landscape">
                <div class="prize-box relative flex items-center justify-center">
                  <img
                    class="prize-img-landscape rounded object-cover"
                    :src="detail.assetsPicUrl"
                  >
                  <prize-tag
                    style="position: absolute; bottom: -12px;"
                    :text="detail.awardsName"
                    :height="24"
                  />
                </div>
              </div>
              <div class="ml-6">
                <div class="text-base font-semibold mb-3">{{ detail.assetsName }}</div>
                <div
                  v-if="currentUserInfo.userName"
                  class="flex items-center"
                >
                  <img
                    class="w-7 h-7 rounded-full border border-dark"
                    :src="currentUserInfo.avatarUrl"
                  >
                  <span class="text-base font-semibold ml-2">{{ currentUserInfo.userName || '' }}</span>
                </div>
                <div class="text-xs mt-14">
                  奖品会在活动结束后统一发放哦
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="not-win-img flex items-center justify-center my-5 md:mb-5">
              <img
                style="width: 240px; height: 120px;"
                src="https://picasso-static.xiaohongshu.com/fe-platform/97aa00d84908a053284bd98e007028a6afee6c2d.png"
              >
            </div>
            <div
              v-if="isLandscape"
              class="text-center text-sm font-medium"
            >
              <div class="mb-2">请不要灰心，希望你成为下一条锦鲤</div>
            </div>
            <div
              v-else
              class="text-center text-sm font-medium mt-8 md:mt-5"
            >
              <div>请不要灰心</div>
              <div class="mb-4 md:mb-0">希望你成为下一条锦鲤</div>
            </div>
          </template>
        </div>
      </div>
    </van-dialog>
  </van-config-provider>
</template>

<script lang='ts'>
  import {
    defineComponent, ref, computed, onMounted,
  } from 'vue'
  import { Dialog, ConfigProvider } from 'vant'
  import { getUserInfoFromLocalStorage } from '@xhs/efficiency-tool'
  import { isEmpty } from 'lodash'
  import CloseIcon from '~/assets/close.svg'
  import ArrowRightIcon from '~/assets/arrow-right.svg'
  import { isMobile, isIpad } from '~/utils/browserCheck'
  import PrizeTag from './PrizeTag.vue'
  import PrizeResultTitle from './PrizeResultTitle.vue'

  export default defineComponent({
    name: 'WinPrizeModal',

    components: {
      VanDialog: Dialog.Component,
      VanConfigProvider: ConfigProvider,
      PrizeResultTitle,
      PrizeTag,
    },

    props: {
      detail: {
        type: Object,
        default: () => ({}),
      },
    },

    emits: ['close'],

    setup(props, { emit }) {
      const show = ref(true)
      const isLandscape = ref(false)

      const modalWidth = computed(() => {
        if (!isMobile || isIpad) {
          return 640
        }
        if ((isMobile && !isIpad) && isLandscape.value) {
          return 560
        }
        return 320
      })

      const isWin = computed(() => !isEmpty(props.detail))

      const modalTitle = computed(() => {
        if (isWin.value) {
          return '恭喜你中奖啦'
        }
        if (isLandscape.value || !isMobile || isIpad) {
          return '很遗憾，本轮未中奖'
        }
        return '很遗憾\n本轮未中奖'
      })

      const setLandscapeOrientation = () => {
        isLandscape.value = window.orientation === 90 || window.orientation === -90
      }

      const currentUserInfo = computed(() => {
        const userInfo = getUserInfoFromLocalStorage()
        return userInfo
      })

      onMounted(() => {
        setLandscapeOrientation()
        const eventname = 'onorientationchange' in window ? 'orientationchange' : 'resize'
        window.addEventListener(eventname, () => {
          if (isMobile) {
            setLandscapeOrientation()
          }
        })
      })

      return {
        CloseIcon,
        ArrowRightIcon,

        show,
        isLandscape,
        modalWidth,
        modalTitle,
        isWin,

        currentUserInfo,

        handleClose() {
          emit('close')
        },
      }
    },
  })
</script>
<style lang="stylus" scoped>
  .content-box
    background-image linear-gradient(180deg, #FFCC77 0%, #EB3B23 100%)
    padding 2px
    border-radius 24px
  .content
    border-radius 24px
    padding 30px 16px 22px 16px
    @media screen and (max-width:960px) and (orientation:landscape)
      padding 20px 32px 12px 32px
  .prize-wrapper
    width 180px
    height 180px
    border-radius 4px
    background-image linear-gradient(180deg, #FFCC77 0%, #EB3B23 100%)
    margin auto
    position relative
    padding 1px
  .prize-wrapper-landscape
    width 136px
    height 136px
    flex-shrink 0
    margin 0
    border-radius 4px
    background-image linear-gradient(180deg, #FFCC77 0%, #EB3B23 100%)
    position relative
    padding 1px
  .prize-box
    width 100%
    height 100%
    border-radius 4px
    background #FFF8EC
  .prize-img
    width 172px
    height 172px
  .prize-img-landscape
      width 128px
      height 128px
  .not-win-img
    @media screen and (max-width:960px) and (orientation:landscape)
      margin 6px 0px 12px 0px
</style>
