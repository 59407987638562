<template>
  <transition name="chat-modal">
    <div
      v-if="visible"
      class="chat-modal"
      @click.stop=""
    >
      <a-spin
        class="chat-modal-spin"
        :spinning="loading"
      />
      <div class="chat-modal-header">
        <span class="chat-modal-header-title">{{ title }}</span>
        <span
          class="chat-modal-header-icon"
          @click="handleClose"
          v-html="CHAT_BOX_ICONS.CLOSE_ICON"
        />
      </div>
      <div class="chat-modal-content">
        <slot name="content" />
      </div>
      <div
        v-if="showFooter"
        class="chat-modal-footer"
      >
        <slot name="footer">
          <a-button
            class="bg-gray-brand text-gray-02 border-gray-04"
            style="margin-right:16px"
            @click="handleCancel"
          >取消</a-button>
          <a-button
            type="primary"
            @click="handleConfirm"
          >确认</a-button>
        </slot>
      </div>
    </div>
  </transition>
</template>
<script lang='ts'>
  import { defineComponent } from 'vue'
  import { CHAT_BOX_ICONS } from './config'

  export default defineComponent({
    props: {
      title: { type: String, required: false, default: '请确认' },
      visible: { type: Boolean, required: true, default: false },
      loading: { type: Boolean, required: false, default: false },
      showFooter: { type: Boolean, required: false, default: true },
    },
    emits: ['update:visible', 'ok', 'cancel'],
    setup(_, { emit }) {
      function handleClose() {
        emit('update:visible', false)
      }

      function handleConfirm() {
        emit('ok')
      }

      function handleCancel() {
        emit('cancel')
      }

      return {
        CHAT_BOX_ICONS,
        handleClose,
        handleConfirm,
        handleCancel,
      }
    },
  })
</script>
<style lang='stylus' scoped>
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
  .chat-modal
    width: 320px
    position: absolute
    top: 20px
    left: 0
    left: -50%
    padding: 0 16px
    background-color: #19191F
    z-index: 1000
    filter: drop-shadow(0 4px 20px rgba(0,0,0,.5))
    border-radius: 8px
    &-spin
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%,-50%)
      z-index: 10000
    &-header
      height: 64px
      display: flex
      align-items: center
      justify-content: space-between
      color: #D5D7DD
      font-size: 16px
      &-icon
        cursor: pointer
    &-content
      padding-bottom: 20px
    &-footer
      height: 72px
      display: flex
      align-items: center
      justify-content: flex-end
    &.chat-modal-enter-active
        animation: bounce-in 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03)
    &.chat-modal-leave-active
      animation: bounce-in 0.3s cubic-bezier(0.01, 0.71, 0.21, 1.03) reverse
</style>
